
const initialState = {
    device_theme: null,
    manual_toggle:null,

}
export const darkMode = (appstate = initialState, action) =>{
    switch (action.type) {
        case "GOTO_LOGIN":
            return{
                ...appstate,
                gotoLogin:action.payload
            }

        case "DARK_MODE_TOGGLE_DEVICE":
            return{
                ...appstate,
                device_theme:action.payload
            }

        case "DARK_MANUAL_TOGGLE":
            return{
                ...appstate,
                manual_toggle:action.payload
            }

        case "SINGLE_POST_TOGGLE":
            return{
                ...appstate,
                single_post_open:action.payload
            }
    
        default:
            return{
                ...appstate
            }
    }
}

