
import React from "react";
import ReactDOM from "react-dom";
import { Auth0Provider } from "@auth0/auth0-react";

import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { registerServiceWorker } from './serviceWorker';
import reportWebVitals from "./reportWebVitals";
import { domain as auth0Domain, clientId, callbackUri, iosOrAndroid } from "./auth.config";
import {Provider} from 'react-redux';
import { store } from "./redux/store";
import AppNative from "./AppNative";
import MiniDrawer, { AppTest } from "./App.test";
import DrawerApp from "./DrawerApp";


ReactDOM.render(
  <React.StrictMode>
    <Provider store={store} >
    <Auth0Provider
      domain={auth0Domain}
      clientId={clientId}
      authorizationParams={{
        redirect_uri: callbackUri
      }}
      // For using Auth0-React with Ionic on Android and iOS,
      // it's important to use refresh tokens without the fallback
      useRefreshTokens={true}
      useRefreshTokensFallback={false}
      cacheLocation="localstorage"
    >
      {
        iosOrAndroid?(
          <AppNative />
        ):(
          <App />
        )
      }
    </Auth0Provider>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA

registerServiceWorker();
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
