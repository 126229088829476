import { IonBackButton, IonButton, IonButtons, IonCardTitle, IonCheckbox, IonCol, IonContent, IonFooter, IonGrid, IonHeader, IonIcon, IonItem, IonLabel, IonPage, IonRadio, IonRadioGroup, IonRouterLink, IonRow, IonToolbar } from '@ionic/react';

import { arrowBack, shapesOutline } from "ionicons/icons";
import CustomField from './CustomField';
import { useSignupFields } from './fields';
import { useEffect, useState } from 'react';
import { validateForm } from './util';
import { useHistory, useParams } from 'react-router';
import styles from "./CustomField.module.css";
import './Signup.css';
import { useAuth0 } from '@auth0/auth0-react';
import { Browser } from '@capacitor/browser';
import { createUser } from '../../utils/auth0';
import Loader from '../../utils/Loader';
import { signup } from '../../utils/requests';

const Signup = () => {

    const params = useParams();
    const fields = useSignupFields();
    const [ errors, setErrors ] = useState(false);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [ loading, setLoading ] = useState(false);
    const [ signupUp, setSignupUp ] = useState(false);

    const { loginWithRedirect,isAuthenticated } = useAuth0();
    const [ comp_size, setCompSize ] = useState('1')
    const history = useHistory()


    const createAccount = () => {

        const errors = validateForm(fields);
        setErrors(errors);

        console.log(errors, "err")

        if (!errors.length) {
          // setLoading(true);
            const email = document.getElementById('email').value
            const company = document.getElementById('company').value
            const location = document.getElementById('location').value
            const name = document.getElementById('name').value
            const password = document.getElementById('password').value
            const how_hear = document.getElementById('hear').value
            const row = {email, password, comp_size, company, location, name, datasets:selectedOptions, how_hear};
            signup({...row}).then(response=>{
              setLoading(false)
              setSignupUp(true);

            })
            .catch(error=>{
              setLoading(false);
              setSignupUp(false);
              alert(error.response.data.error)
            })
        }
    }

    useEffect(() => {

        return () => {

            fields.forEach(field => {
              if(['size', 'dataset'].includes(field).id === false){
                field.input.state.reset("")
              }
            });
            setErrors(false);
        }
    }, [params]);
    function handleOptionChange(event) {
      console.log(event)
      const value = event.detail.value;
      if (selectedOptions.includes(value)) {
        setSelectedOptions(selectedOptions.filter((option) => option !== value));
      } else {
        setSelectedOptions([...selectedOptions, value]);
      }
    }

    const handleLogin = async(e) =>{
      e.preventDefault();
      try{
        await loginWithRedirect({
          async openUrl(url) {
            await Browser.open({
              url,
              windowName: "_self"
            });
          }
        });
        
      }
      catch(e){
        
      }
    }
    
	return (
    <div className="signup-container">
      {loading&&<div className='loading-signup' > <Loader size={100} /> </div>}
      {
        signupUp?(
          <div className="signup-success">
            <div className="success-header">
              <div onClick={()=>history.push('/landing')} className="signup-back-btn">
                <IonIcon color="light" icon={arrowBack} style={{ fontSize:'20px' }}  />
              </div>
            </div>
            <div className="success-container">
              <p> Thank you for registering. Check your emails for login link. </p>
              <IonRouterLink href='/'  >Continue</IonRouterLink>
            </div>
          </div>
        ):(
          <IonGrid className="ion-padding signup-grid">
                    <IonRow>
                      
                        <IonCol className='signup-col' sizeMd="8"  sizeSm='12' >
                        
                            <div onClick={()=>history.push('/landing')} className="signup-back-btn">
                              <IonIcon color="light" icon={arrowBack} style={{ fontSize:'20px' }}  />
                            </div>
                            <IonCardTitle>Sign up</IonCardTitle>
                            <h5>Start a 14 day free trial</h5>
                        </IonCol>
                    </IonRow>

                    <IonRow className="ion-margin-top ion-padding-top">
                        <IonCol className='signup-col' sizeMd='8' sizeSm='12'  >

                            { fields.map(field => {

                                if(field.id === "size"){
                                  return (
                                    <div style={{ display:'block', width:"100%", marginBottom: '1rem'}}>
                                      <IonLabel className="field-ion-label">
                                        Company Size
                                      </IonLabel>
                                      <IonRadioGroup onIonChange={(e)=>{
                                        setCompSize(e.detail.value)
                                      }} className='radio-group' value={comp_size}>
                                        
                                        {
                                          field.inputs.map(item=>(
                                            <IonItem className='ion-custom-item' >
                                              <p className='radio-label' >{item.label}</p>
                                              <IonRadio value={item.value} />
                                            </IonItem>
                                          ))
                                        }

                                      </IonRadioGroup>
                                    </div>
                                  )
                                }
                                if(field.id === "dataset"){
                                  return(
                                    <div style={{ display:'block', width:"100%", marginBottom: '1rem'}}>
                                      <IonLabel className="field-ion-label">
                                        Which datasets are you most interested in?
                                      </IonLabel>
                                        
                                        {
                                          field.inputs.map((item, index)=>(
                                            <IonItem className='ion-custom-item' key={index}>
                                              <p className="radio-label" >{item}</p>
                                              {/* <IonCheckbox
                                                value={item}
                                                checked={}
                                                onIonChange={handleOptionChange}
                                              /> */}
                                              <input checked={selectedOptions.includes(item)} value={item} onChange={(e)=>{
                                                console.log(e.target.value, "lllll")
                                                  const value = e.target.value;
                                                  if (selectedOptions.includes(value)) {
                                                    setSelectedOptions(selectedOptions.filter((option) => option !== value));
                                                  } else {
                                                    setSelectedOptions([...selectedOptions, value]);
                                                  }
                                              }}  type="checkbox" className='dataset-checkbox'/>
                                            </IonItem>
                                          ))
                                        }

                                    </div>
                                  )
                                }
                                else{
                                  return(
                                    <CustomField field={ field } errors={ errors } />
                                  )
                                }
                            })}

                            <IonButton className="custom-button" expand="block" onClick={ createAccount }>Create account</IonButton>
                            <p style={{ display:"flex", alignItems:'center' }} >
                                Already have an account? 
                                <p onClick={(e)=>handleLogin(e)} className='signup-login-btn' > LOGIN</p> 
                            </p>
                        </IonCol>
                    </IonRow>
                </IonGrid>
        )
      }
    </div>
		// <IonPage>
    //   <div className='signup-container' >
		// 	<IonContent fullscreen>
                
		// 	</IonContent>

		// 	<IonFooter>
		// 		<IonGrid className="ion-no-margin ion-no-padding">
            
		// 		</IonGrid>
		// 	</IonFooter>
    //   </div>
		// </IonPage>
	);
};

export default Signup;