


const initialState = {
    error:null
}


export const error = (state=initialState, action) =>{
    switch (action.type) {
        case 0:
            return{
                ...state,
                error:"You have encountered with network error. Please try again later"
            }
        case 1:
            return{
                ...state,
                error:null
            }
        default:
            return{
                ...state
            }
    }
}