import { IonContent, IonToggle } from '@ionic/react';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import './Header.css';
function Header({isDmOn}) {
    const [ isDarkMode, setDMBtn ] = useState(isDmOn);
    const darkModeState = useSelector(state=>state.darkModeState)
    const dispatch = useDispatch();

    const [logo, setLogo] = useState("https://ik.imagekit.io/kfo1upt6um8y/Logo/NI_Logo_bY5fL0rcU.png");

    // useEffect(()=>{
    //     // Check for dark mode 
    //     const {state, window} = JSON.parse(localStorage.getItem('dark-mode')) || {state:false, window:'mobile'}
    //     if(state){
    //         setDM(state);
    //         document.body.classList.add('dark');
    //         setLogo("https://ik.imagekit.io/kfo1upt6um8y/Logo/NI_Logo_Neg_fKt0Drd5Q.png");
            
    //     }
    //     else{
    //         setDM(false);
    //         document.body.classList.remove('dark');
    //         setLogo("https://ik.imagekit.io/kfo1upt6um8y/Logo/NI_Logo_bY5fL0rcU.png");
    //     }
        
        
    // }, [])


    const setLayout = (mode) =>{
        if(mode === 'dark'){
        document.body.classList.add('dark')
        document.body.classList.remove('light')
          setDMBtn(true);
          setLogo('https://ik.imagekit.io/kfo1upt6um8y/Logo/NI_Logo_Neg_fKt0Drd5Q.png')
        }
        else{
            document.body.classList.remove('dark')
            document.body.classList.add('light')
          setDMBtn(false);
          setLogo("https://ik.imagekit.io/kfo1upt6um8y/Logo/NI_Logo_bY5fL0rcU.png");
        }
      }
    
    useEffect(()=>{
        const prefersDark = window.matchMedia('(prefers-color-scheme: dark)');
        const prefersLight = window.matchMedia('(prefers-color-scheme: light)');
        
        if(prefersDark.matches){
            setLayout('dark');
            dispatch({type:"CURRENT_THEME", payload:"DARK"})
        }
        if(prefersLight.matches){

            dispatch({type:"CURRENT_THEME", payload:"LIGHT"});
            setLayout('light')
        }


        prefersDark.addListener((mediaQuery) => {
        
            if(!mediaQuery.matches){
                dispatch({type:"CURRENT_THEME", payload:"LIGHT"});
                setLayout('light')
            }
            else{
            dispatch({type:"CURRENT_THEME", payload:"DARK"});
            setLayout('dark');
            // document.body.classList.remove("light");
            // document.body.classList.add("dark");
            // dispatch({type:"DARK_MODE_TOGGLE_DEVICE", payload:"DARK"})
            }
            // toggleDarkTheme(mediaQuery.matches)
        });


    }, [])
    

    
  return (
    <IonContent>
        <div className="header">
        <div className="header-brand">
            <img src={logo} alt="" />
        </div>
        <div className="toggle">
            <IonToggle
                name='Dark Mode'
                checked={isDarkMode}
                onIonChange={e=>{
                    const {checked} = e.detail;
                    if(checked){
                        setLayout('dark')
                        dispatch({type:"CURRENT_THEME", payload:"DARK"});
                        return
                        
                    }
                    else{
                        setLayout('light')
                        dispatch({type:"CURRENT_THEME", payload:"LIGHT"});
                        return
                    }
                }}
                color="dark"
            />
            <div className="darkmode">Dark Mode</div>
        </div>
    </div>
    </IonContent>
  )
}

export default Header