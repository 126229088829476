
const initialState = {
    currentTheme: null

}
export const appTheme = (appstate = initialState, action) =>{
    switch (action.type) {
        case "CURRENT_THEME":
            return{
                ...appstate,
                currentTheme:action.payload
            }
        
        default:
            return{
                ...appstate
            }
    }
}

