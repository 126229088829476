import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import {
  IonApp,
  IonContent,
  IonHeader,
  IonIcon,
  IonPage,
  IonRouterOutlet,
  IonSplitPane,
  IonTabBar,
  IonTabButton,
  IonTabs,
  isPlatform,
  setupIonicReact,
  
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import {home, apps, search, person, bookmarkOutline} from 'ionicons/icons';

import Bookmarks from './pages/Bookmarks';
import { useAuth0 } from '@auth0/auth0-react';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';
import './App.css';
import './global.css';
/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import Home from './pages/Home';
import './pages/home.css';
import Categories from './pages/Categories';
import Landing from './pages/Auth/Landing';
import Accounts from './pages/Accounts';
import Loader from './utils/Loader';
import { useEffect } from 'react';
// import {messaging} from "./firebase";
import { App as CapApp } from '@capacitor/app'
import { Browser } from '@capacitor/browser';
import { callbackUri } from './auth.config';
import {useDispatch, useSelector} from 'react-redux';
import {useState} from 'react';
import ErrorPage from './pages/Error/ErrorPage';

import { PushNotifications} from '@capacitor/push-notifications';
import { LocalNotifications } from '@capacitor/local-notifications';
import { Toast } from "@capacitor/toast";
import Header from './components/Header';
import Menu from './components/Menu';
import Search from './pages/Search';
import { fcmToken, sendToken, Test, updateImpression } from './utils/requests';
import SinglePost from './components/SinglePost';
import ReactPullToRefresh from 'react-pull-to-refresh';
import Signup from './pages/Auth/Signup';
import { Capacitor } from '@capacitor/core';


// const messaging = firebase.messaging()

setupIonicReact();

// Protected route
// App's main screen which is an Ionic tab component
const Tabs = React.memo(() =>{
  const {user} = useAuth0();
  const nullEntry = []
  const [notifications, setnotifications] = useState(nullEntry);
  const [breakpoint, setBreakPoint] = useState(window.screen.width);
  const impressionContext = useSelector(state=>state.impressionState);
  const singlePostState = useSelector(state=>state.singlePostState);
  const [ locNotification, setLocNotification ] = useState(null)
  const [ isDmOn, setDMON ] = useState(null);
  const dispatch = useDispatch();

  

  const handle_fcm_token = async (token) =>{
    try{
      console.log(token, "token")
      const response = await fcmToken(user.email, token);
    }
    catch(e){
      console.log(JSON.stringify(e), "errorrororo")

    }
  }

  useEffect(()=>{
    var iOS = /iPad|iPhone|iPod|Mac/.test(navigator.userAgent) && !window.MSStream; 
    const persist_page_post_id = localStorage.getItem('persist_page');
    if(persist_page_post_id){
      console.log(persist_page_post_id, "postt iddd")
      dispatch({type:"SINGLE_POST_TOGGLE", payload:persist_page_post_id})
    }
    

    window.addEventListener('resize', ()=>{
      setBreakPoint(window.innerWidth);
    })

    return ()=>window.removeEventListener('resize', ()=>{
      setBreakPoint(window.innerWidth);
    })
  }, []);

  // navigator.serviceWorker.onmessage = event =>{
  //   if(event.data.messageType === "notification-clicked"){
  //     const {post_id} = event.data.data;
  //     const {single_post_open} = singlePostState;
  //     if(single_post_open===null){
  //       dispatch({type:"SINGLE_POST_TOGGLE", payload:post_id})
  //     }
  //   }
  // }


  

  


  // Notification permission for web
  function isIOS() {
    const browserInfo = navigator.userAgent.toLowerCase();
    
    if (browserInfo.match('iphone') || browserInfo.match('ipad')) {
      return true;
    }
    if (['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(navigator.platform)) {
      return true;
    } 
    return false;
  }
  // function requestPermission(){
  //   if(isIOS()) return;
  //   else{
  //     try{
  //       Notification.requestPermission().then(permission=>{
  //         if(permission === "granted"){
  //           console.log("Location granted");
  //           getToken(messaging, {vapidKey:'BIEoq1wjBB6CQBR3ig3Tdj3vKzaxdOIuMoOssy7KzCjUhK07haRmnYyRpcVm5q5psYygCa4E2SBAERJ8fHEd-_c'}).then(tok=>{
  //             console.log(tok)
  //             handle_fcm_token(tok);
                
  //             }).catch(e=>{
  //               console.log(e, "err");
  //             })
  //         }
  //         else{
  //           alert('Notification permission denied, Please allow notification in your browser')
  //         }
  //       })
  //     }
  //     catch(e){
  //       console.log(e)
  //     }
  //   }
    
  // }
  const showToast = async (msg) => {
    await Toast.show({
        text: msg
    })
  }


  useEffect(()=>{
    
    // requestPermission();
    

    // Notification permission for mobile

    PushNotifications.checkPermissions().then((res) => {
      if (res.receive !== 'granted') {
        PushNotifications.requestPermissions().then((res) => {
          if (res.receive === 'denied') {
            showToast('Push Notification permission denied');
          }
          else {
            showToast('Push Notification permission granted');
            register();
          }
        })
        .catch(e=>{
          console.log("permission error", e);
        })
      }
      else {
        register();
      }
    });
  }, [])

    const register = () => {
      console.log('Initializing HomePage');

      // Register with Apple / Google to receive push via APNS/FCM
      PushNotifications.register();

      // On success, we should be able to receive notifications
      PushNotifications.addListener('registration', (token)=>{
         var {fcm_token, createdAt, serverUpdated} = localStorage.getItem('fcm')?JSON.parse(localStorage.getItem('fcm')):{fcm_token:null}
          console.log(token.value, "tokenyyyyy")
         handle_fcm_token(token.value);
      });

      // Some issue with our setup and push will not work
      PushNotifications.addListener('registrationError',
          (error) => {
            console.log("errororoororor reg", JSON.stringify(error))
              alert('Error on registration: ' + JSON.stringify(error));
          }
      );

      // Show us the notification payload if the app is open on our device
      PushNotifications.addListener('pushNotificationReceived',
          (notification) => {
              console.log("notification recieved", JSON.stringify(notification));
              setLocNotification(notification);
              LocalNotifications.schedule({
                notifications:[

                  {
                    title:notification.title,
                    body:notification.body,
                    extra:{post_id:notification.data.post_id},
                    id: 1,
                    schedule:{at: new Date(Date.now)}
                  }
                ]
              })
              setnotifications(notifications => [...notifications, { id: notification.id, title: notification.title, body: notification.body, type: 'foreground' }])
          }
      );


      // Method called when tapping on a notification
      PushNotifications.addListener('pushNotificationActionPerformed',
          (notification) => {
              console.log(JSON.stringify(notification), "notifi mriiri", notification.notification.data, "pppp");
              dispatch({type:"SINGLE_POST_TOGGLE", payload:notification.notification.data.post_id})
              setnotifications(notifications => [...notifications, { id: notification.notification.data.id, title: notification.notification.data.title, body: notification.notification.data.body, type: 'action' }])
          }
      );
      // LocalNotifications.addListener('localNotificationActionPerformed', (notification)=>{

      //   console.log(notification, "fuckkkkkk", post_id)
      //   const {post_id} = notification.notification.data;
      //   dispatch({type:"SINGLE_POST_TOGGLE", payload:post_id})
      // })
      LocalNotifications.addListener('localNotificationActionPerformed', (notification)=>{
        console.log(JSON.stringify(notification), "action per")
        dispatch({type:"SINGLE_POST_TOGGLE", payload:notification.notification.extra.post_id});
      })
      
    }

  const handleTabChange = (event) =>{
    const {impressions} = impressionContext;
    
    if(impressions.length > 0){
      updateImpression(user.email, impressions)
      .then(response=>{
        dispatch({type:"REMOVE_IMPRESSIONS", payload:null})
      })
      .catch(error=>{
        console.log(error.message)
      })
    }
  }

  const isMobile = breakpoint  < 768;



  const handleDmSwitch = () =>{
    setDMON(!isDmOn)
  }
  return(
    <IonPage className='main-container' >
      <IonHeader>
      <div style={{
        position:'fixed',
        height:'5rem',
        width:'100%',
        zIndex:1000,
        marginTop:isPlatform('android')?0:'50px'
      }}>
          <Header isDmOn={isDmOn} handleDmSwitch={handleDmSwitch} />
        </div>
      </IonHeader>
        
        <div style={{
          display:'flex',
          position:'relative',
          flexDirection:'column',
          height:isPlatform('android')?'100%':'calc(100vh - 80px)',
          marginTop:isPlatform('android')?'81px':'140px'

        }}>
            
            
            <IonReactRouter >
              <IonTabs onIonTabsDidChange={(e) => dispatch({type:"TAB_CHANGED", payload:e.detail.tab})} >
              
              <IonRouterOutlet >
                <Redirect exact path="/" to="/home" />
                <Route path="/home" component={Home}  />
                <Route path="/categories" component={Categories} exact/>
                <Route path="/bookmarks" component={Bookmarks} exact />
                <Route path="/search" component={Search} exact />
                <Route path="/accounts" component={Accounts} exact />
                <Route path="/post/:id" component={SinglePost} exact />
              </IonRouterOutlet>
              <IonTabBar slot="bottom">
                <IonTabButton tab="home" href="/home">
                  <IonIcon style={{ fontSize:'18px' }}  icon={home} />
                </IonTabButton>
                <IonTabButton tab="categories" href='/categories'>
                  <IonIcon style={{ fontSize:'18px' }}  icon={apps} />
                </IonTabButton>
                <IonTabButton tab="search" href="/search">
                  <IonIcon style={{ fontSize:'18px' }}  icon={search} />
                </IonTabButton>
                <IonTabButton tab="bookmarks" href="/bookmarks">
                  <IonIcon style={{ fontSize:'18px' }}  icon={bookmarkOutline} />
                </IonTabButton>
                <IonTabButton tab="accounts" href="/accounts">
                  <IonIcon style={{ fontSize:'18px' }}  icon={person} />
                </IonTabButton>
              </IonTabBar>
              </IonTabs>
            </IonReactRouter>
      </div>
      
    </IonPage>
  )
})


// Redirect to auth pages if user is not logged in
const PrivateRoute = ({element, path, isAuthenticated, email_verified}) =>{


  // const dispatch = useDispatch();
  if(isAuthenticated && email_verified){
    return <Route exact path={path} component={element} />
  }
  else{
    const path = window.location.pathname;
    if(/^\/post\/\d+$/.test(path)){
      localStorage.setItem('persist_page', path.split('/').slice(-1)[0] )
    }
    return <Redirect to={"/landing"} />
  }
}

const AuthRoute = ({element, path, isAuthenticated,email_verified}) =>{

  if(isAuthenticated&&email_verified){
    return <Redirect from={path} to={"/"} />
  }
  else{
    return <Route path={path} component={element} exact />
  }
}


// Main router for the App component to seperate protected routes
const MainRouter = () =>{
  const { isLoading, isAuthenticated, user} = useAuth0();


  console.log(isAuthenticated, isLoading, "nnnnnnnn")

  
  
  
  return(
    <IonApp className='main-router-app' >
        {
          isLoading&&!isAuthenticated?(
            <Loader size={100}/>
          ):(
            <IonReactRouter>
              <AuthRoute path="/landing" element={Landing} isAuthenticated={isAuthenticated} email_verified={user?user.email_verified:false} />
              {/* <Route path="/login" component={Login} exact /> */}
              <Route path="/signup" component={Signup} exact />
              <PrivateRoute path={"/"} element={Tabs} isAuthenticated={isAuthenticated} email_verified={user?user.email_verified:false} />
          </IonReactRouter>
          )
        }
    </IonApp>
  )
}



// App entry component which is wrapped in auth0 provider
// const App = () =>{
//   const { logout, error, isLoading, user } = useAuth0();
//   const errorContext = useSelector(state=>state.error);
//   const dispatch = useDispatch();
//   const [error_, setError] = useState(false);
//   const nullEntry = []
//   const [notifications, setnotifications] = useState(nullEntry);
//   // const messaging = getMessaging(app);

//   // CapApp.addListener('pause', ()=>{
//   //   console.log("paused")
//   //   const { impressions } = impressionContext;
//   //   if(impressions.length > 0){
//   //     updateImpression(user.email, impressions)
//   //     .then(response=>{
//   //       console.log(response.data);
//   //       dispatch({type:"REMOVE_IMPRESSIONS", payload:null})
//   //     })
//   //     .catch(error=>{
//   //       console.log(error.message)
//   //     })
//   //   }
//   // })
//   // CapApp.addListener('appStateChange', ({isActive})=>{
//   //   console.log(isActive, "isactive")
//   // })
  
//     // useEffect(() => {
//     //   // Handle the 'appUrlOpen' event and call `handleRedirectCallback`
//     //   CapApp.addListener('appUrlOpen', async ({ url }) => {
//     //     console.log(url, "url")
//     //     if (url.includes('state') && (url.includes('code') || url.includes('error'))) {
//     //       await handleRedirectCallback(url);
//     //     }
//     //     // No-op on Android
//     //     await Browser.close();
//     //   });
//     // }, [handleRedirectCallback]);

//   useEffect(()=>{

    
//     // if(prefersDark.matches){
//     //   // document.body.classList.add("dark");
//     //   // document.body.classList.remove("light");
//     //   dispatch({type:"DARK_MODE_TOGGLE_DEVICE", payload:"DARK"})
//     // }
//     // if(prefersLight.matches){
//     //   // document.body.classList.add("light");
//     //   // document.body.classList.remove("dark");

//     //   dispatch({type:"DARK_MODE_TOGGLE_DEVICE", payload:"LIGHT"})
//     //   // toggleDarkTheme(prefersDark.matches)
//     //   // console.log('dark')
//     // }

//     // Listen for changes to the prefers-color-scheme media query
    
//     // Add or remove the "dark" class based on if the media query matches
//     function toggleDarkTheme(shouldAdd) {
//       dispatch({type:"DARK_MODE_TOGGLE", payload:shouldAdd})
//     }
//     if(Error && isLoading){
//       logout();
//     }
//   }, [])

//   useEffect(()=>{
//     if(errorContext.error!==null){
//       setError(errorContext.error)
//     }
//     else{
//       setError(false);
//     }
//   }, [errorContext])



  


//   // const prefersDark = window.matchMedia('(prefers-color-scheme: dark)');
//   // prefersDark.addListener((mediaQuery) => {
//   //   console.log("dark toogle")
//   // });


//   // Update the impression when app closes or inactive 

//   useEffect(()=>{
    
//     localStorage.removeItem('manual-toggle')


//     // window.addEventListener('blur', ()=> dispatch({type:"TAB_CLOSED", payload:''}) )
//     // window.addEventListener('close', ()=> dispatch({type:"TAB_CLOSED", payload:''})  );
    
    
//     return ()=>{
//         window.removeEventListener('blur', ()=>console.log("blur") )
//         window.removeEventListener('close', ()=>console.log("close") )
//       }
//     }, []);


  
//   return(
//     <>
//     {
//       error_?(
//         <ErrorPage message={error} />
//       ):(
//         <div>Helllllllo</div>
//       )
//     }
//     </>
//   )
// }


const AppNative = () =>{
  const {handleRedirectCallback} = useAuth0();
  useEffect(() => {
  // Handle the 'appUrlOpen' event and call `handleRedirectCallback`
    CapApp.addListener('appUrlOpen', async ({ url }) => {
      console.log(url, "url")
      if (url.includes('state') && (url.includes('code') || url.includes('error'))) {
        await handleRedirectCallback(url);
      }
      // No-op on Android
      await Browser.close();
    });
    }, [handleRedirectCallback]);
  return(
    <MainRouter />
  )
}
export default AppNative;
