import {
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonMenu,
  IonMenuToggle,
  IonToggle,
} from '@ionic/react';

import { useLocation } from 'react-router-dom';
import { apps,bookmarkOutline,  home,  person, search} from 'ionicons/icons';
import './Menu.css';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

interface AppPage {
  url: string;
  iosIcon: string;
  mdIcon: string;
  title: string;
}

const appPages: AppPage[] = [
  {
    title: 'Home',
    url: '/home',
    iosIcon: home,
    mdIcon: home
  },
  {
    title: 'Categories',
    url: '/categories',
    iosIcon: apps,
    mdIcon: apps
  },
  {
    title: 'Search',
    url: '/search',
    iosIcon: search,
    mdIcon: search
  },
  {
    title: 'Bookmarks',
    url: '/bookmarks',
    iosIcon: bookmarkOutline,
    mdIcon: bookmarkOutline
  },
  // {
  //   title: 'Brand Directory',
  //   url: '/bookmarks',
  //   iosIcon: bookmarkOutline,
  //   mdIcon: bookmarkOutline
  // },
  {
    title: 'Accounts',
    url: '/accounts',
    iosIcon: person,
    mdIcon: person
  }
];


const Menu = () => {
  const location = useLocation();
  const dispatch = useDispatch()
  const [ isDarkMode, setDM ] = useState(false);
  const darkModeContext = useSelector(state=>state.darkModeState);
  const [ logo, setLogo] = useState("https://ik.imagekit.io/kfo1upt6um8y/Logo/NI_Logo_bY5fL0rcU.png");

  // useEffect(()=>{
  //   const {state, window} = JSON.parse(localStorage.getItem('dark-mode')) || {state:false, window:'mobile'};
  //   if(state){
  //     document.body.classList.add('dark');
  //     setDM(true);
  //     setLogo("https://ik.imagekit.io/kfo1upt6um8y/Logo/NI_Logo_Neg_fKt0Drd5Q.png");
  //   }
  //   else{
  //       document.body.classList.remove('dark');
  //       setDM(false);
  //       setLogo("https://ik.imagekit.io/kfo1upt6um8y/Logo/NI_Logo_bY5fL0rcU.png");
  //   }
    
    
  // }, [])



  const setLayout = (mode) =>{
    if(mode === 'dark'){
      document.body.classList.add('dark')
      document.body.classList.remove('light')
      setDM(true);
      setLogo('https://ik.imagekit.io/kfo1upt6um8y/Logo/NI_Logo_Neg_fKt0Drd5Q.png')
    }
    else{
      document.body.classList.remove('dark')
      document.body.classList.add('light')
      setDM(false);
      setLogo("https://ik.imagekit.io/kfo1upt6um8y/Logo/NI_Logo_bY5fL0rcU.png");
    }
  }

  useEffect(()=>{
    const prefersDark = window.matchMedia('(prefers-color-scheme: dark)');
    const prefersLight = window.matchMedia('(prefers-color-scheme: light)');
    console.log(prefersDark, prefersLight)
    if(prefersDark.matches){
      setLayout('dark')
      dispatch({type:"CURRENT_THEME", payload:"DARK"});
    }
    if(prefersLight.matches){
      setLayout('light')
      dispatch({type:"CURRENT_THEME", payload:"LIGHT"});
    }


    prefersDark.addListener((mediaQuery) => {
      console.log(mediaQuery.matches, "med mateched")
      if(!mediaQuery.matches){
        setLayout('light');
        dispatch({type:"CURRENT_THEME", payload:"LIGHT"});
      }
      else{
        setLayout('dark')
        dispatch({type:"CURRENT_THEME", payload:"DARK"});
        // document.body.classList.remove("light");
        // document.body.classList.add("dark");
        // dispatch({type:"DARK_MODE_TOGGLE_DEVICE", payload:"DARK"})
      }
      // toggleDarkTheme(mediaQuery.matches)
    });


  }, [])

  const handleChangeDM = async(e) =>{
    e.preventDefault()
    const {checked} = e.detail;
    if(!checked){
      document.body.classList.add('light');
      document.body.classList.remove('dark');
      setLogo("https://ik.imagekit.io/kfo1upt6um8y/Logo/NI_Logo_bY5fL0rcU.png");
    }
    else{
      document.body.classList.add('dark');
      document.body.classList.remove('light');
      setLogo("https://ik.imagekit.io/kfo1upt6um8y/Logo/NI_Logo_Neg_fKt0Drd5Q.png");
    }
    setDM(checked);
  }

  return (
    <IonMenu contentId="main" type="overlay">
      <IonContent>
        <IonList id="inbox-list">
          <div className="logo">
            <img src={logo} alt="" />
          </div> 
          <div className="menu-custom-items">
          {appPages.map((appPage, index) => {
            return (
              <IonMenuToggle key={index} autoHide={false}>
                <IonItem className={location.pathname === appPage.url ? 'selected' : ''} routerLink={appPage.url} routerDirection="none" lines="none" detail={false}>
                  <IonIcon aria-hidden="true" slot="start" ios={appPage.iosIcon} md={appPage.mdIcon} />
                  <IonLabel className='ionlabel-toggle'>{appPage.title}</IonLabel>
                </IonItem>
              </IonMenuToggle>
            );
          })}
          
          </div>
          <div className="toggle-custom">
            <IonToggle
              color='dark'
              checked={isDarkMode}
              onIonChange={(e)=>{
                
                const {checked} = e.detail;
                
                if(checked){
                  
                  dispatch({type:"CURRENT_THEME", payload:"DARK"});
                  setLayout('dark')
                  return
                }
                else{
                  dispatch({type:"CURRENT_THEME", payload:"LIGHT"});
                  setLayout('light');
                  return
                }

              }}
              
            />
            <p className='darkmode-text'>Dark Mode</p>
          </div>
        </IonList>
      </IonContent>
    </IonMenu>
  );
};

export default Menu;
