// import { BOOKMARK_ID } from "../types";

const initialState = {
    impressions:[]
}

export const impressionReducer = (state=initialState, action) =>{
    switch (action.type) {
        case "ADD_IMPRESSIONS":
            return{
                ...state,
                impressions:action.payload
            }
        case "REMOVE_IMPRESSIONS":
            return{
                ...state,
                impressions:[]
            }
        
        default:
            return{
                ...state
            }
    }
}