// import { appsSharp } from "ionicons/icons"

const initialState = {
    
    tab:"HOME",
    prevTab:"HOME"

}
export const tabEvent = (appstate = initialState, action) =>{
    switch (action.type) {
        
        case "TAB_CHANGED":
            return{
                ...appstate,
                tab:action.payload,
                prevTab:appstate.tab
            }
        case "TAB_CLOSED":
            return{
                ...appstate,
                tab:null,
                prevTab:null
            }
    
        default:
            return{
                ...appstate
            }
    }
}

