import { useAuth0 } from '@auth0/auth0-react';
import { IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonPage, isPlatform } from '@ionic/react'
import { arrowBack } from 'ionicons/icons'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux';
import { getCategoryFeed } from '../utils/requests';
import HomeList from './HomeList'

function CatModel({open, setOpen, catFeed, handleBookmarkBtn, tag}) {
    const [ feed, setFeed ] = useState([...catFeed]);
    const [ page, setPage ] = useState(1);
    const [  loading, setLoading] = useState(false);
    const scrollerRef = useRef(null);
    const {user} = useAuth0();
    const dispatch = useDispatch()
    useEffect(()=>{
        
        if(page > 1){
            getCategoryFeed(user.email,tag, page)
            .then(response=>{
                setFeed(prev=>[...prev, ...response.data]);
                setLoading(false);
            })
            .catch(e=>{
                if(!e.status){
                    dispatch({type:0, payload:null})
                }
            })
        }
        
      }, [page])
    useEffect(() => {
        const container = scrollerRef.current;
        const elements = [];
        let ts = [];
        const handleScroll = () => {
          var children = container.children;
          var el_check = [...elements];
          for(var i=0; i<children.length; i++){
            var child = children[i];
            if(child.getBoundingClientRect().y <= 300){
                let curChild_id = child.getAttribute('data-post-id')
                if(!elements.includes(curChild_id) && curChild_id !== undefined){
                    elements.unshift(curChild_id);
                    const date = new Date(Date.now()).toISOString().replace('T', ' ').replace('Z', ' ')
                    ts.unshift(date);
                }
            }
            }
            if(el_check.length !== elements.length){
                var final_array = [];
                for(var i=0; i<elements.length; i++){
                    final_array.unshift([elements[i], ts[i]])
                }
                dispatch({type:"ADD_IMPRESSIONS", payload:final_array})
            }
          if (
            container.scrollTop + container.clientHeight + 0.5>= container.scrollHeight &&
            !loading
          ) {
            setPage((prevPage) => prevPage + 1);
          }
        };
        if(container){
            container.addEventListener("scroll", handleScroll);
        }
      }, [loading]);
  return (
      <div className="cat-modal">
          <div className="cat-modal-header">
            <IonButtons slot="end">
              <IonButton onClick={() => setOpen({open:false, state:{}})}>
                <IonIcon color="dark" icon={arrowBack} />
              </IonButton>
            </IonButtons>
            <div className="cat-modal-header-title">
              {open.state.title} 
            </div>
          </div>
          <div className='cat-feed-container' style={{
            width:"100%",
            height:'100%',
            overflowY:'scroll',
            paddingTop:'5rem'
          }} ref={scrollerRef} >
            {
              feed.map((item, index)=>{
                return(
                  <HomeList key = {index} item={item} from="category" /> 
                )
              })
            }
          </div>
        </div>
  )
}

export default CatModel