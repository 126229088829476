
import { initializeApp,  } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";

import {onBackgroundMessage} from 'firebase/messaging/sw'
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyD9_GUZI6PIse7Rx09olsdIwLS-uO-SZQk",
  authDomain: "anie-insights.firebaseapp.com",
  projectId: "anie-insights",
  storageBucket: "anie-insights.appspot.com",
  messagingSenderId: "351686133264",
  appId: "1:351686133264:web:9130b8445b907fe545ec95",
  measurementId: "G-YG40RPMEMR"
};

// Initialize Firebase

export const app = initializeApp(firebaseConfig);

let messaging;




try{
    messaging = getMessaging(app);

}
catch(err){
  console.log("error messaging", err)
    console.error('Failed to initialize Firebase Messaging', err);
}

// self.addEventListener('notificationclick', (event) => {
//   console.log('On notification click:', event.notification);
// });



export {messaging};





const analytics = getAnalytics(app);

