
const initialState = {
    gotoLogin:false,
    // darkmode:JSON.parse(localStorage.getItem('dark-mode')) || {state:false, window:'mobile', shouldAdd:false},
    single_post_open:null,
    device_theme: null,

}
export const app = (appstate = initialState, action) =>{
    switch (action.type) {
        case "GOTO_LOGIN":
            return{
                ...appstate,
                gotoLogin:action.payload
            }

       

        case "SINGLE_POST_TOGGLE":
            return{
                ...appstate,
                single_post_open:action.payload
            }
        case "TAB_CHANGED":
            return{
                ...appstate,
                tab:action.payload,
                prevTab:appstate.tab
            }
        case "TAB_CLOSED":
            return{
                ...appstate,
                tab:null,
                prevTab:null
            }
    
        default:
            return{
                ...appstate
            }
    }
}

