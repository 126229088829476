const endpoint_production = "https://anie-23-express.azurewebsites.net";
const endpoint_dev = "http://localhost:3001";

// export const app_env = "development";

export const app_env = "production";

export const api_endpoint = app_env==="development"?endpoint_dev:endpoint_production;



