import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import {
  IonApp,
  IonContent,
  IonIcon,
  IonPage,
  IonRouterOutlet,
  IonSplitPane,
  IonTabBar,
  IonTabButton,
  IonTabs,
  setupIonicReact,
  
} from '@ionic/react';
import { IonReactRouter} from '@ionic/react-router';
import {home, apps, search, person, bookmarkOutline} from 'ionicons/icons';

import Bookmarks from './pages/Bookmarks';
import { useAuth0 } from '@auth0/auth0-react';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';
import './App.css';
import './global.css';
/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import Home from './pages/Home';
import './pages/home.css';
import Categories from './pages/Categories';
import Landing from './pages/Auth/Landing';
import Accounts from './pages/Accounts';
import Loader from './utils/Loader';
import { useEffect } from 'react';
// import {messaging} from "./firebase";
import { App as CapApp } from '@capacitor/app'
import { Browser } from '@capacitor/browser';
import { callbackUri } from './auth.config';
import {useDispatch, useSelector} from 'react-redux';
import {useState} from 'react';
import ErrorPage from './pages/Error/ErrorPage';

import { PushNotifications} from '@capacitor/push-notifications';
import { LocalNotifications } from '@capacitor/local-notifications';
import { Toast } from "@capacitor/toast";
import Header from './components/Header';
import Menu from './components/Menu';
import Search from './pages/Search';
import { messaging } from './firebase'
import { getMessaging, getToken, onMessage } from 'firebase/messaging'
import { app } from './firebase';
import { fcmToken, sendToken, Test, updateImpression } from './utils/requests';
import SinglePost from './components/SinglePost';
import ReactPullToRefresh from 'react-pull-to-refresh';
import Signup from './pages/Auth/Signup';


// const messaging = firebase.messaging()

setupIonicReact();

// Protected route
// App's main screen which is an Ionic tab component
const Tabs = React.memo(() =>{
  const {user} = useAuth0();
  const nullEntry = []
  const [notifications, setnotifications] = useState(nullEntry);
  const [breakpoint, setBreakPoint] = useState(window.screen.width);
  const impressionContext = useSelector(state=>state.impressionState);
  const singlePostState = useSelector(state=>state.singlePostState)
  const [ isDmOn, setDMON ] = useState(null);
  const dispatch = useDispatch();

  

  const handle_fcm_token = async (token) =>{
    try{
      const response = await fcmToken(user.email, token);
    }
    catch(e){
      console.log(e.message, "errorrororo")

    }
  }

  useEffect(()=>{
    var iOS = /iPad|iPhone|iPod|Mac/.test(navigator.userAgent) && !window.MSStream; 
    const persist_page_post_id = localStorage.getItem('persist_page');
    if(persist_page_post_id){
      dispatch({type:"SINGLE_POST_TOGGLE", payload:persist_page_post_id})
    }
    

    window.addEventListener('resize', ()=>{
      setBreakPoint(window.innerWidth);
    })

    return ()=>window.removeEventListener('resize', ()=>{
      setBreakPoint(window.innerWidth);
    })
  }, []);

  navigator.serviceWorker.onmessage = event =>{
    if(event.data.messageType === "notification-clicked"){
      const {post_id} = event.data.data;
      const {single_post_open} = singlePostState;
      if(single_post_open===null){
        dispatch({type:"SINGLE_POST_TOGGLE", payload:post_id})
      }
    }
  }


  

  


  // Notification permission for web
  function isIOS() {
    const browserInfo = navigator.userAgent.toLowerCase();
    
    if (browserInfo.match('iphone') || browserInfo.match('ipad')) {
      return true;
    }
    if (['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(navigator.platform)) {
      return true;
    } 
    return false;
  }
  function requestPermission(){
    if(isIOS()) return;
    else{
      try{
        Notification.requestPermission().then(permission=>{
          if(permission === "granted"){
            console.log("Location granted");
            getToken(messaging, {vapidKey:'BIEoq1wjBB6CQBR3ig3Tdj3vKzaxdOIuMoOssy7KzCjUhK07haRmnYyRpcVm5q5psYygCa4E2SBAERJ8fHEd-_c'}).then(tok=>{
              console.log(tok, "lkkkkkk")
              handle_fcm_token(tok);
                
              }).catch(e=>{
                console.log(e, "err");
              })
          }
          else{
            alert('Notification permission denied, Please allow notification in your browser')
          }
        })
      }
      catch(e){
        console.log(e)
      }
    }
    
  }
  const showToast = async (msg) => {
    await Toast.show({
        text: msg
    })
  }


  useEffect(()=>{
    
    requestPermission();
    

    // Notification permission for mobile

    // PushNotifications.checkPermissions().then((res) => {
    //   if (res.receive !== 'granted') {
    //     PushNotifications.requestPermissions().then((res) => {
    //       if (res.receive === 'denied') {
    //         showToast('Push Notification permission denied');
    //       }
    //       else {
    //         showToast('Push Notification permission granted');
    //         register();
    //       }
    //     })
    //     .catch(e=>{
    //       console.log("permission error", e);
    //     })
    //   }
    //   else {
    //     register();
    //   }
    // });
  }, [])

    // const register = () => {
    //   console.log('Initializing HomePage');

    //   // Register with Apple / Google to receive push via APNS/FCM
    //   PushNotifications.register();

    //   // On success, we should be able to receive notifications
    //   PushNotifications.addListener('registration', (token)=>{
    //      var {fcm_token, createdAt, serverUpdated} = localStorage.getItem('fcm')?JSON.parse(localStorage.getItem('fcm')):{fcm_token:null}
          
    //      handle_fcm_token(token.value);
    //   });

    //   // Some issue with our setup and push will not work
    //   PushNotifications.addListener('registrationError',
    //       (error) => {
    //         console.log("errororoororor reg")
    //           alert('Error on registration: ' + JSON.stringify(error));
    //       }
    //   );

    //   // Show us the notification payload if the app is open on our device
    //   PushNotifications.addListener('pushNotificationReceived',
    //       (notification) => {
    //           LocalNotifications.schedule({
    //             notifications:[

    //               {
    //                 title:notification.title,
    //                 body:notification.body,
    //                 data:notification.data,
    //                 id: parseInt(notification.id),
    //                 schedule:{at: new Date(Date.now)}
    //               }
    //             ]
    //           })
    //           setnotifications(notifications => [...notifications, { id: notification.id, title: notification.title, body: notification.body, type: 'foreground' }])
    //       }
    //   );


    //   // Method called when tapping on a notification
    //   PushNotifications.addListener('pushNotificationActionPerformed',
    //       (notification) => {
    //           console.log(JSON.stringify(notification), "notifi mriiri", notification.notification.data, "pppp");
    //           dispatch({type:"SINGLE_POST_TOGGLE", payload:notification.notification.data.post_id})
    //           setnotifications(notifications => [...notifications, { id: notification.notification.data.id, title: notification.notification.data.title, body: notification.notification.data.body, type: 'action' }])
    //       }
    //   );
    //   LocalNotifications.addListener('localNotificationActionPerformed', (notification)=>{
    //     const {post_id} = notification.notification.data;
    //     dispatch({type:"SINGLE_POST_TOGGLE", payload:post_id})
    //   })
      
    // }

  const handleTabChange = (event) =>{
    const {impressions} = impressionContext;
    
    if(impressions.length > 0){
      updateImpression(user.email, impressions)
      .then(response=>{
        dispatch({type:"REMOVE_IMPRESSIONS", payload:null})
      })
      .catch(error=>{
        console.log(error.message)
      })
    }
  }

  const isMobile = breakpoint  < 768;



  const handleDmSwitch = () =>{
    setDMON(!isDmOn)
  }
  return(
    <IonPage className='main-container' >
      
      <div className='app-container'>
            <>
              {
                breakpoint < 768 &&(
                  <div className="header-app">
                    <Header isDmOn={isDmOn} handleDmSwitch={handleDmSwitch} />
                  </div>
                )
              }
            </>
            
            {
              isMobile?(
                <IonReactRouter >
                  <IonTabs onIonTabsDidChange={(e) => dispatch({type:"TAB_CHANGED", payload:e.detail.tab})} >
                  
                  <IonRouterOutlet >
                    <Redirect exact path="/" to="/home" />
                    <Route path="/home" component={Home}  />
                    <Route path="/categories" component={Categories} exact/>
                    <Route path="/bookmarks" component={Bookmarks} exact />
                    <Route path="/search" component={Search} exact />
                    <Route path="/accounts" component={Accounts} exact />
                    <Route path="/post/:id" component={SinglePost} exact />
                  </IonRouterOutlet>
                  <IonTabBar className='bottom-tab' slot="bottom">
                    <IonTabButton tab="home" href="/home">
                      <IonIcon style={{ fontSize:'18px' }}  icon={home} />
                    </IonTabButton>
                    <IonTabButton tab="categories" href='/categories'>
                      <IonIcon style={{ fontSize:'18px' }}  icon={apps} />
                    </IonTabButton>
                    <IonTabButton tab="search" href="/search">
                      <IonIcon style={{ fontSize:'18px' }}  icon={search} />
                    </IonTabButton>
                    <IonTabButton tab="bookmarks" href="/bookmarks">
                      <IonIcon style={{ fontSize:'18px' }}  icon={bookmarkOutline} />
                    </IonTabButton>
                    <IonTabButton tab="accounts" href="/accounts">
                      <IonIcon style={{ fontSize:'18px' }}  icon={person} />
                    </IonTabButton>
                  </IonTabBar>
                  </IonTabs>
                </IonReactRouter>
              ):(
                <IonContent>
                  <IonReactRouter forceRefresh={false} >
                  <IonSplitPane when="md" contentId="main">
                    <Menu />
                    <IonRouterOutlet id="main">
                      <Route path="/home" exact={true}>
                        <Home />
                      </Route>
                      <Route path="/categories" exact={true}>
                        <Categories />
                      </Route>
                      <Route path="/search" exact={true}>
                        <Search />
                      </Route>
                      <Route path="/bookmarks" exact={true}>
                        <Bookmarks />
                      </Route>
                      <Route path="/accounts" exact={true}>
                        <Accounts />
                      </Route>
                      <Route path="/post/:id" exact={true}>
                        <SinglePost />
                      </Route>

                      <Route path="/" exact={true}>
                        <Redirect to="/home" />
                      </Route>
                    </IonRouterOutlet>
                  </IonSplitPane>
                </IonReactRouter>
                </IonContent>
              )
              
            }
      </div>
    </IonPage>
  )
})


// Redirect to auth pages if user is not logged in

const AuthRoute = ({element, path, isAuthenticated, email_verified }) =>{

  if(isAuthenticated && email_verified){
    return <Redirect from={path} to={"/"} />
  }
  else{
    return <Route path={path} component={element} exact />
  }
}
const PrivateRoute = ({element, path, isAuthenticated, email_verified}) =>{
  // const dispatch = useDispatch();
  if(isAuthenticated && email_verified){
    return <Route exact path={path} component={element} />
  }
  else{
    const path = window.location.pathname;
    if(/^\/post\/\d+$/.test(path)){
      localStorage.setItem('persist_page', path.split('/').slice(-1)[0] )
    }
    return <Redirect to={"/landing"} />
  }
}


// Main router for the App component to seperate protected routes
const MainRouter = () =>{
  const { isLoading, isAuthenticated, user } = useAuth0();
  const [ singlePostOpen, setOpen  ] = useState({open:false, id:null});
  // const appState = useSelector(state=>state.appState);
  // const singlePostState = useSelector(state=>state.singlePostState);
  

  
  // useEffect(()=>{
  //   const {single_post_open} = appState
  //   console.log(JSON.stringify(single_post_open), "single")
  //   if(single_post_open!==null){
  //       // history.push(`/post/${single_post_open}`);
  //       setOpen({open:true, id:single_post_open});
  //   }
    
  //   }, [singlePostState])
  return(
    <IonApp className='main-router-app' >
        {
          isLoading ?(
            <Loader size={100}/>
          ):(
            <IonReactRouter>
              <AuthRoute path="/landing" element={Landing} isAuthenticated={isAuthenticated} email_verified={user?user.email_verified:false} />
              {/* <Route path="/login" component={Login} exact /> */}
              <Route path="/signup" component={Signup} exact />
              <PrivateRoute path={"/"} element={Tabs} isAuthenticated={isAuthenticated} email_verified={user?user.email_verified:false}  />
          </IonReactRouter>
          )
        }
        {/* {
          singlePostOpen.open&&(
            <SinglePost isopen={singlePostOpen.open} id= {singlePostOpen.id}/>
          )
        } */}

    </IonApp>
  )
}



// App entry component which is wrapped in auth0 provider
const App = React.memo(() =>{
  const { handleRedirectCallback, logout, error:Error, isLoading, user } = useAuth0();
  const errorContext = useSelector(state=>state.error);
  const impressionContext = useSelector(state=>state.impressionState);
  const dispatch = useDispatch();
  const [error, setError] = useState(false);
  const nullEntry = []
  const [notifications, setnotifications] = useState(nullEntry);
  // const messaging = getMessaging(app);

  // CapApp.addListener('pause', ()=>{
  //   console.log("paused")
  //   const { impressions } = impressionContext;
  //   if(impressions.length > 0){
  //     updateImpression(user.email, impressions)
  //     .then(response=>{
  //       console.log(response.data);
  //       dispatch({type:"REMOVE_IMPRESSIONS", payload:null})
  //     })
  //     .catch(error=>{
  //       console.log(error.message)
  //     })
  //   }
  // })
  // CapApp.addListener('appStateChange', ({isActive})=>{
  //   console.log(isActive, "isactive")
  // })
  useEffect(() => {
    CapApp.addListener("appUrlOpen", async ({ url }) => {
      if (url.startsWith(callbackUri)) {
          if (
            url.includes("state") &&
            (url.includes("code") || url.includes("error"))
          ) {
            await handleRedirectCallback(url);
          }

          await Browser.close();
        }
      });
    }, [handleRedirectCallback]);

  useEffect(()=>{

    
    // if(prefersDark.matches){
    //   // document.body.classList.add("dark");
    //   // document.body.classList.remove("light");
    //   dispatch({type:"DARK_MODE_TOGGLE_DEVICE", payload:"DARK"})
    // }
    // if(prefersLight.matches){
    //   // document.body.classList.add("light");
    //   // document.body.classList.remove("dark");

    //   dispatch({type:"DARK_MODE_TOGGLE_DEVICE", payload:"LIGHT"})
    //   // toggleDarkTheme(prefersDark.matches)
    //   // console.log('dark')
    // }

    // Listen for changes to the prefers-color-scheme media query
    
    // Add or remove the "dark" class based on if the media query matches
    function toggleDarkTheme(shouldAdd) {
      dispatch({type:"DARK_MODE_TOGGLE", payload:shouldAdd})
    }
    if(Error && isLoading){
      logout();
    }
  }, [])

  useEffect(()=>{
    if(errorContext.error!==null){
      setError(errorContext.error)
    }
    else{
      setError(false);
    }
  }, [errorContext])



  


  // const prefersDark = window.matchMedia('(prefers-color-scheme: dark)');
  // prefersDark.addListener((mediaQuery) => {
  //   console.log("dark toogle")
  // });


  // Update the impression when app closes or inactive 

  useEffect(()=>{
    
    localStorage.removeItem('manual-toggle')


    // window.addEventListener('blur', ()=> dispatch({type:"TAB_CLOSED", payload:''}) )
    // window.addEventListener('close', ()=> dispatch({type:"TAB_CLOSED", payload:''})  );
    
    
    return ()=>{
        window.removeEventListener('blur', ()=>console.log("blur") )
        window.removeEventListener('close', ()=>console.log("close") )
      }
    }, []);


  
  return(
    <>
    {
      error?(
        <ErrorPage message={error} />
      ):(
        <MainRouter />
      )
    }
    </>
  )
})

export default App;