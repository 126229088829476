import { useAuth0 } from '@auth0/auth0-react';
import { Browser } from '@capacitor/browser';
import {  IonContent, IonItem, IonLabel, IonList, IonPage, IonSelect, IonSelectOption } from '@ionic/react'
import React, { useState } from 'react'
import { useHistory } from 'react-router';
import { callbackUri } from '../auth.config';
import Header from '../components/Header';
import Loader from '../utils/Loader';
import './Accounts.css';


function Accounts() {
    const [selectedValue, setSelectedValue] = useState('');
    const {logout, isLoading} = useAuth0();
    const handleLogout = async() =>{
        await logout({
            async openUrl(url) {
              await Browser.open({
                url,
                windowName: "_self",
              });
            },
            logoutParams: {
              returnTo: callbackUri
            }
          });
    }
  return (
    <div style={{display:'flex', width:'100%', height:'100vh', }} >
        <IonContent id="main" >
            <div className='accounts-top' >
                Account
            </div>
            {
                isLoading?(
                    <Loader size={50} />
                ):(
                    <IonList style={{
                        backgroundColor:'var(--ion-background-color)'
                    }} className='account-list' >
                        <IonItem className='account-item' button>
                            <IonLabel  onClick={()=>handleLogout()} >Logout</IonLabel>
                        </IonItem>
                        {/* <IonItem className='account-item' button>
                            <IonLabel>Billing</IonLabel>
                        </IonItem>
                        <IonItem className='account-item'>
                            <IonLabel>Notification preference</IonLabel>
                            <IonSelect interface="popover" value={selectedValue} onIonChange={e => setSelectedValue(e.detail.value)}>
                            <IonSelectOption value="option1">{window.innerWidth}</IonSelectOption>
                            <IonSelectOption value="option2">Option 2</IonSelectOption>
                            <IonSelectOption value="option3">Option 3</IonSelectOption>
                            </IonSelect>
                        </IonItem>
                        <IonItem className='account-item'>
                            <IonLabel>Feed preferences</IonLabel>
                            <IonSelect interface="popover" value={selectedValue} onIonChange={e => setSelectedValue(e.detail.value)}>
                            <IonSelectOption value="option1">Option 1</IonSelectOption>
                            <IonSelectOption value="option2">Option 2</IonSelectOption>
                            <IonSelectOption value="option3">Option 3</IonSelectOption>
                            </IonSelect>
                        </IonItem> */}
                    </IonList>
                )
            }
        </IonContent>
    </div>
  )
}

export default Accounts


