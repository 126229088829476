
const initialState = {
    // darkmode:JSON.parse(localStorage.getItem('dark-mode')) || {state:false, window:'mobile', shouldAdd:false},
    single_post_open:null,


}
export const singlePost = (appstate = initialState, action) =>{
    switch (action.type) {
        

        case "SINGLE_POST_TOGGLE":
            return{
                ...appstate,
                single_post_open:action.payload
            }
        
        default:
            return{
                ...appstate
            }
    }
}

