import { useAuth0 } from '@auth0/auth0-react';
import { IonButton, IonButtons, IonContent, IonIcon, IonModal } from '@ionic/react';
import { arrowBack } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import Loader from '../utils/Loader';
import { getSinglePost } from '../utils/requests';
import CatModel from './CatModel';
import HomeList from './HomeList';
import './SinglePost.css'


function SinglePost({route}) {
    const [ open, setOpen ] = useState({state:false, post:[], loading:true, error:null});
    const darkModeContext = useSelector(state=>state.darkModeState);
    const dispatch = useDispatch();
    const [ darkMode, setDM ] = useState(darkModeContext.device_theme === "DARK");
    const {user} = useAuth0();
    const history = useHistory();
    const params = useParams();
    useEffect(()=>{
        const {id} = params;
        console.log(id, "fuckking id")
        getSinglePost(user.email, id)
        .then(response=>{
            setOpen({...open, state:true, loading:false, post:response?[response]:null})
            dispatch({type:"SINGLE_POST_TOGGLE", payload:null})
        })
        .catch(error=>{
            setOpen({...open, error:error.message, loading:false, state:true, post:[]})
            dispatch({type:"SINGLE_POST_TOGGLE", payload:null})
        })
    }, [])

    const handleBookmarkBtn = () =>{

    }
    const handleClose = () =>{
        localStorage.removeItem('persist_page');
        dispatch({type:"SINGLE_POST_TOGGLE", payload:null})
        setOpen({...open,loading:false, post:[], state:false});
        window.location.href = "/"
    }


    

  return (
    <IonModal isOpen={open.state} onDidDismiss={()=>{
        handleClose();
    }
        
    } className='fullscreen-modal' >
        {
            open.loading?(
                <Loader size={100} />
            ):(
                <div className="singlepost-modal">
                    {
                    open.post.map((item, index)=>{
                        return(
                        <HomeList singlepost={true} handleClose={handleClose} key = {index} item={item} handleBookmarkBtn={handleBookmarkBtn} isbkmrked={item.bookmarks.length > 0}/> 
                        )
                    })
                    }
                    </div>
            )
        }
    </IonModal>
  )
}

export default SinglePost