import React from 'react'
// import {BallTriangle, Bars, TailSpin} from 'react-loader-spinner';
import {Bars, TailSpin} from 'react-loader-spinner';

function Loader({size, spin}) {
  return (
    <div style={{
        display:'flex',
        flex:1,
        justifyContent:'center',
        alignItems:"center",
      }}>
        {
          spin?(
          <TailSpin
              height={size}
              width={size}
              color="grey"
              ariaLabel="tail-spin-loading"
              radius="1"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
            />
          
          ):(
            <Bars
              height={size}
              width={size}
              radius={5}
              color="grey"
              ariaLabel="ball-triangle-loading"
              wrapperClass={{}}
              wrapperStyle=""
              visible={true}
            />
          )
        }
        
        
      </div>
  )
}

export default Loader