import { BOOKMARK_ID } from "../types";

const initialState = {
    bkmrk_count:0,
    post_added_bk:null,
    post_removed_bk:null,
    id:null
}
export const bookmarkReducer = (state=initialState, action) =>{
    switch (action.type) {
        case "REMOVE_BOOKMARK":
            return{
                ...state,
                post_removed_bk : action.payload
            }
        case "ADD_BOOKMARK":
            return{
                ...state,
                post_added_bk : action.payload
            }
        case "RESET_BOOKMARK":
            return{
                ...state,
                post_added_bk:null,
                post_removed_bk:null,
            }
        case "BKMRK_NOTIFICATION":
            
            return{
                ...state,
                bkmrk_count:state.bkmrk_count + action.payload
            }
        case BOOKMARK_ID:
            return{
                ...state,
                id:action.payload
            }
        default:
            return{
                ...state
            }
    }
}