import React, { useEffect, useState } from 'react'
import './landing.css';
import { useAuth0 } from '@auth0/auth0-react';
import { Browser } from '@capacitor/browser';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { isPlatform } from '@ionic/react';


function Landing() {
  const { loginWithRedirect,isAuthenticated, user } = useAuth0();
  const appState =  useSelector(state=>state.appState);
  const history = useHistory();
  console.log(user, "userr");

  useEffect(()=>{
    if(user && user.email_verified===false){
      alert("Email verification pending")
    }
  }, [])
  const [logo, setLogo] = useState("https://ik.imagekit.io/kfo1upt6um8y/Logo/NI_Logo_bY5fL0rcU.png");

  const handleLogin = async(e) =>{
    e.preventDefault();
    try{
      await loginWithRedirect({
        async openUrl(url) {
          await Browser.open({
            url,
            windowName: "_self"
          });
        }
      });
      
    }
    catch(e){
      
    }
  }

  useEffect(()=>{
    const prefersDark = window.matchMedia('(prefers-color-scheme: dark)');
    const prefersLight = window.matchMedia('(prefers-color-scheme: light)');
    
    if(prefersDark.matches){
      setLogo("https://ik.imagekit.io/kfo1upt6um8y/Logo/NI_Logo_Neg_fKt0Drd5Q.png");
    }
    if(prefersLight.matches){
      setLogo("https://ik.imagekit.io/kfo1upt6um8y/Logo/NI_Logo_bY5fL0rcU.png");
    }


    prefersDark.addListener((mediaQuery) => {
      console.log(mediaQuery.matches, "med mateched")
      if(!mediaQuery.matches){
        setLogo("https://ik.imagekit.io/kfo1upt6um8y/Logo/NI_Logo_bY5fL0rcU.png");
      }
      else{
        setLogo("https://ik.imagekit.io/kfo1upt6um8y/Logo/NI_Logo_Neg_fKt0Drd5Q.png");

      }

    });


  }, [])
  return (
    <div className='landing_container' >
      <div className='landing-logo' >
        <img src={logo} alt={"logo"} />
      </div>
      <div className='landing-btn' >
        <button  onClick={(e)=>handleLogin(e)} >
          LOGIN
        </button>
        {
          !isPlatform('hybrid')&&(
            <button  onClick={()=>history.push('/signup')} >
              SIGNUP
            </button>
          )
        }
        {/* {window.innerWidth}-{window.screen.width} */}
      </div>
      
    </div>
  )
}

export default Landing;



