import React from 'react'
import './errorpage.css';
function ErrorPage({message}) {
  return (
    <div className='error-container' >
        <div className='logo' >
            <img src={require('../../dummydata/logo.png')} alt="logo" />
        </div>
        <div className='message' >
            {message}
        </div>
    </div>
  )
}

export default ErrorPage