import { useAuth0 } from '@auth0/auth0-react';
// import { IonContent,IonPage } from '@ionic/react';
import { IonContent, } from '@ionic/react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../utils/Loader';
import { getbookmarks, handleBookmark } from '../utils/requests';
import {useHistory} from 'react-router';

import './Bookmarks.css';
// import BookmarkList from '../components/BookmarkList';
import HomeList from '../components/HomeList';
// import Header from '../components/Header';

const Bookmarks = () => {
  // const [ readmore, setreadMore ] = useState(false);
  const [ bookmarks, setBkmk ] = useState([]);
  const [ loading, setLoading ] = useState(true);
  const [ bkmrkUpdate, setBKUpdate ] = useState(true);
  const {user} = useAuth0();
  const dispatch = useDispatch()
  const history  = useHistory();
  const bkmrkState = useSelector(state=>state.bookmark);

  useEffect(()=>{

    getbookmarks(user.email)
      
      .then(bkmk=>{
        setBkmk(bkmk)
        setLoading(false);
      })
      .catch(error=>{
        setLoading(false);
      })
  }, [])
  const handleBookmarkBtn = async(item) =>{
    handleBookmark(user.email, item.post_id)
    .then(({data, status})=>{
      setBKUpdate(false);
      var temp = [...bookmarks].filter(bk=>bk.post_id !== item.post_id);
      setBkmk(temp);
      dispatch({type:'REMOVE_BOOKMARK', payload:item});
      setBKUpdate(true);
      if(temp.length === 0){
        history.push("/home");
      }
      return 204
    })
    .catch(error=>{
      console.log(error)
    })
}

  useEffect(()=>{
    const {post_added_bk, post_removed_bk} = bkmrkState;
    if(post_added_bk !== null){
      var temp = [...bookmarks];
      if(!temp.includes(post_added_bk)){
        temp = [post_added_bk, ...temp];
        dispatch({type:"RESET_BOOKMARK", payload:null});
      }
      setBkmk(temp);

    }
    if(post_removed_bk !== null){
      if(bookmarks.filter(bk=>bk.post_id === post_removed_bk.post_id).length>0){
        temp = [...bookmarks].filter(bk=>bk.post_id !== post_removed_bk.post_id);
        
        setBkmk(temp);
        dispatch({type:"RESET_BOOKMARK", payload:null});
      }
    }
  }, [bkmrkState])
  return (
    <div style={{display:'flex', width:'100%', height:'100vh', }} >
        {
          loading?(
            <Loader size={40} />
          ):(
            <IonContent fullscreen={false}>
              {
                  bookmarks.map(item=>(
                      <HomeList key={item.post_id}  item={item} handleBookmarkBtn={handleBookmarkBtn} isbkmrked={item.bookmarks.length > 0}/>
                  ))
              }
            </IonContent>
          )
        }
    </div>
  );
};

export default Bookmarks;
