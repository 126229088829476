


// import { arrowBackOutline, bookmarkOutline, bookmarksSharp, card, expand } from 'ionicons/icons';
import { arrowBackOutline, bookmarkOutline, bookmarksSharp } from 'ionicons/icons';
import moment from 'moment';
import React, { useCallback, useEffect, useRef, useState } from 'react'

// import { IonButton, IonIcon, IonImg, IonModal, isPlatform } from '@ionic/react';
import {  IonIcon, IonModal, isPlatform } from '@ionic/react';
import { useDispatch, useSelector } from 'react-redux';
import { handleBookmark } from '../utils/requests';
import { useAuth0 } from '@auth0/auth0-react';
import MuxPlayer from "@mux/mux-player-react";

import { useGesture } from '@use-gesture/react';
import { useSpring, animated } from 'react-spring';
import QuickPinchZoom, { make3dTransformValue } from "react-quick-pinch-zoom";


import { marked } from 'marked';
// import { Swiper, SwiperSlide } from 'swiper/react';
// import { Autoplay, Keyboard, Pagination, Scrollbar, Zoom } from 'swiper';

import 'swiper/css';
import '@ionic/react/css/ionic-swiper.css';
import 'swiper/css/zoom';
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
// import { BorderBottom, PictureAsPdfRounded } from '@material-ui/icons';
import {  PictureAsPdfRounded } from '@material-ui/icons';
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import axios from 'axios';
import { api_endpoint } from '../api.config';
import Loader from '../utils/Loader';
import html2pdf from 'html2pdf.js';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`
    };
}

const useStyles = makeStyles({
    scrollButtons: {
        "&.Mui-disabled": {
            opacity: 0.3
        }
    }
});





function HomeList({ item, curTheme, singlepost, handleClose, from }) {
    const [downloading, setDownloading] = useState(false);
    const classes = useStyles();
    const [feed, setFeed] = useState({ ...item });
    const [pinched, setPinched] = useState(false)
    const ref = useRef(null);
    const [open, setOpen] = useState({ imgs: [], open: false });
    const [bkmrked, setBKMRKD] = useState(feed.bookmarks.length > 0);
    const bookmarkState = useSelector(state => state.bookmark);
    const dispatch = useDispatch();
    const { user } = useAuth0();
    const [value, setValue] = React.useState(0);
    const [valueModal, setValueModal] = React.useState(0);
    const [readMore, setReadMore] = useState(false);
    const [pdfModal, setPdfModal] = useState({ open: false, post: null })



    const imgRef = useRef();
    const onUpdate = useCallback(({ x, y, scale }) => {
        const { current: img } = imgRef;

        if (img) {
            const value = make3dTransformValue({ x, y, scale });

            img.style.setProperty("transform", value);
        }
    }, []);


    if (item.post_id === 1277) {
        console.log(item.images);
    }
    const imageRef = useRef();
    const imageRefM = useRef();
    const cardRef = useRef();
    const [style, api] = useSpring(() => ({
        x: 0,
        y: 0,
        scale: 1,
        rotateZ: 0,
    }))
    const [styleM, apiM] = useSpring(() => ({
        x: 0,
        y: 0,
        scale: 1,
        rotateZ: 0,
    }))

    useGesture(
        {
            // onHover: ({ active, event }) => console.log('hover', event, active),
            // onMove: ({ event }) => console.log('move', event),

            onDrag: ({ pinching, cancel, offset: [x, y], ...rest }) => {
                api.start({ x, y })
            },

            onPinch: ({ canceled, event, origin: [ox, oy], first, movement: [ms], offset: [s, a], memo }) => {
                console.log("pinchihihihi")
                if (first) {
                    const { width, height, x, y } = imageRef.current?.getBoundingClientRect()
                    const tx = ox - (x + width / 2)
                    const ty = oy - (y + height / 2)
                    memo = [style.x.get(), style.y.get(), tx, ty]
                }

                const x = memo[0] - (ms - 1) * memo[2]
                const y = memo[1] - (ms - 1) * memo[3]
                api.start({ scale: s, rotateZ: a, x, y })
                return memo
            },

            onPinchEnd: () => {
                console.log("cdsjcdjcjdscjd")
            },

        },

        {
            target: imageRef,
            drag: { from: () => [style.x.get(), style.y.get()] },
            pinch: { scaleBounds: { min: 0.5, max: 2 }, rubberband: true },
        }
    )
    useGesture(
        {
            onDrag: ({ pinching, cancel, offset: [dx, dy], ...rest }) => {
                if (!pinched) return cancel();
                const { width, height, x, y } = imageRefM.current?.getBoundingClientRect();
                console.log(width, Math.abs(dy), "ppp")
                apiM.start({ x: dx, y: dy });
            },

            onPinch: ({ canceled, event, origin: [ox, oy], first, movement: [ms], offset: [s, a], memo }) => {
                console.log("pinchihihihi");

                if (first) {
                    const { width, height, x, y } = imageRefM.current?.getBoundingClientRect()
                    const tx = ox - (x + width / 2)
                    const ty = oy - (y + height / 2)
                    memo = [style.x.get(), style.y.get(), tx, ty]
                }

                const x = memo[0] - (ms - 1) * memo[2]
                const y = memo[1] - (ms - 1) * memo[3]
                apiM.start({ scale: s, rotateZ: a, x, y });
                setPinched(true)

                return memo
            },

            onPinchEnd: () => {
                const { width, height, x, y } = cardRef.current?.getBoundingClientRect()
                const img = imageRefM.current?.getBoundingClientRect()

                if (img.width < width) {
                    apiM.start({
                        x: 0,
                        y: 0,
                        scale: 1,
                        rotateZ: 0,
                    })
                }

            },

        },

        {
            target: imageRefM,
            drag: { from: () => [style.x.get(), style.y.get()] },
            pinch: { scaleBounds: { min: 0.5, max: 2 }, rubberband: true },
        }
    )

    const jumpZoom = () => {

    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const handleChangeModal = (event, newValue) => {
        setValueModal(newValue);
    }
    // const [ icon, setIcon ] = useState(appState.darkmode.state?feed.post_tag_icon_dark_mode:item.post_tag_icon);

    // var IconD;
    // var IconL;
    feed.tags.forEach(tag => {

    })

    var filtered_imgs = [];

    feed.images.forEach(img => {
        if (img.img_url.includes("ctfassets")) {
            filtered_imgs.push(`${img.img_url}?w=${window.screen.width}`)
        }
        else {
            filtered_imgs.push(img.img_url.replace(/(\/[^/]+)$/, `/tr:w-${window.screen.width}$1`))
        }
    })

    const hBook = () => {
        handleBookmark(user.email, item.post_id)
            .then(response => {
                if (response.status === 204) {
                    setBKMRKD(false)
                    dispatch({ type: "REMOVE_BOOKMARK", payload: { ...feed, bookmarks: [] } });
                    // dispatch({type:"BKMRK_NOTIFICATION", payload:-1});
                }
                else if (response.status === 201) {
                    setBKMRKD(true)
                    dispatch({ type: "ADD_BOOKMARK", payload: { ...feed, bookmarks: ['bookmarked'] } });
                    // dispatch({type:"BKMRK_NOTIFICATION", payload:1});
                }

            })
            .catch(error => {
                console.log(error)
            })
    }


    useEffect(() => {

        const { post_removed_bk } = bookmarkState;
        if (post_removed_bk !== null && post_removed_bk.post_id === feed.post_id) {
            var updated = { ...feed };
            updated.bookmarks = [];
            setFeed({ ...updated });
            setBKMRKD(false);
        }
    }, [bookmarkState])




    useEffect(() => {

        if (from === "home") {
            const img = document.getElementsByClassName('feed-body-image');
            const readB = document.getElementById(`${feed.post_id}`);

            readB.addEventListener('click', (e) => {
                console.log("hhhhhhhh");
                setReadMore(!readMore);
            })
        }

    }, [])

    let tag_ = null;

    var tag = feed.tags.forEach(tag => {
        if (tag.options) {
            if (tag.options.post_tag_grp === "Dataset") {
                tag_ = tag.options.post_tag;
            }
        }
    })


    const handleDownloadPDF = (htmlContent, title) => {
        const tempElement = document.createElement('div');
        tempElement.innerHTML = htmlContent;
        const formatFileName = (title) => {
                // Define a regular expression pattern to match symbols
                var symbolPattern = /[^\w\s]/g;
                
                // Use the replace method to replace symbols with an empty string
                var cleanText = title.replace(symbolPattern, '');
                
                return cleanText.split(' ').join('-');
        };
        const opt = {
            margin: 10,
            filename: formatFileName(title),
            image: { type: 'svg', quality: 0.98 },
            pagebreak: { avoid: ['#svgToPrint'] },
            // pagebreak: { mode: ['avoid-all'] },
            html2canvas: {
                scale: 2, useCORS: true, 
                onclone: (element) => {
                    const svgElements = Array.from(element.querySelectorAll('svg'))
                    for (const s of svgElements) {
                        const bBox = s.getBBox();
                        console.log(bBox);
                        if (!s.getAttribute('viewBox')) {
                            console.log('no viewBox');
                            s.setAttribute('viewBox', "0 0 1500 800");
                            continue;
                        }
                        else {
                            s.setAttribute("x",  bBox.x);
                            s.setAttribute("y",  bBox.y);
                            s.setAttribute("width", bBox.width);
                            s.setAttribute("height", bBox.height);
                        }
                    }
                }
            },
            jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
        };

        // Create the PDF using html2pdf.js and trigger the download
        html2pdf().set(opt).from(tempElement).save();
        setDownloading(false)
    };



    return (
        <div data-post-id={feed.post_id} className="feed-container">
            <div className="feed-header">
                <div className="feed-header-posttype">
                    {singlepost && <div className="feed-back-icon" onClick={() => handleClose()}>
                        <IonIcon icon={arrowBackOutline} size='30' style={{ fontSize: '20px' }} />
                    </div>}
                    {feed.post_title}
                </div>
                {tag_ && <div className="feed-header-avatar">
                    {tag_}
                </div>}
            </div>
            <div ref={ref} key={feed.post_id} className="feed-body">

                <div className="feed-body-detail">
                    <div className="feed-body-markdown">
                        <div onClick={() => {
                            from !== "home" && setReadMore(!readMore)
                        }} style={{ position: 'relative' }} dangerouslySetInnerHTML={{ __html: readMore ? marked.parse(feed.post_markdown) : marked.parse(feed.post_markdown.slice(0, 200).concat(`...<button class="feed-readmore" id=${feed.post_id} >See more</button>`)) }} >

                        </div>
                    </div>
                </div>
                <div className='feed-body-img' >

                    <AppBar position="static" color="inherit">
                        <Tabs
                            variant="scrollable"
                            allowScrollButtonsMobile
                            scrollButtons="on"
                            value={value}
                            onChange={handleChange}
                            classes={classes}
                            aria-label="simple tabs example"
                            indicatorColor="primary"
                            style={{ display: filtered_imgs.length > 1 ? "flex" : "none", color: 'grey' }}
                        >
                            {feed.images.map((image, index) => {
                                return (
                                    <Tab key={index} label={image.img_caption ? image.img_caption : "IMG 1"} />
                                )
                            })}
                        </Tabs>
                    </AppBar>
                    {[...filtered_imgs].map((url, index) => {
                        return (
                            <TabPanel key={index} value={value} index={index}>
                                <div>
                                    {
                                        feed.video_url ? (
                                            <MuxPlayer
                                                streamType="on-demand"
                                                playbackId={feed.video_url}
                                            />
                                        ) : (
                                            <img onClick={() => setOpen({ imgs: feed.images, open: true })} src={url} className="feed-body-image" alt={feed.post_caption} />
                                        )
                                    }
                                </div>
                            </TabPanel>
                        )
                    })}

                </div>


            </div>
            <div className="feed-footer">
                <div className="feed-footer-detail">
                    <div className="feed-footer-detail-date">
                        {moment(feed.post_created).format('LL')}
                    </div>

                    <div className="feed-footer-detail-date">
                        By {feed.author.author_name}
                    </div>
                </div>
                <div className="feed-footer-seperate"></div>
                <div className="feed-footer-bkmrk">
                    <div className='feed-footer-bkmrk-btn' onClick={(e) => {
                        hBook()
                    }} style={{ }} >
                        <IonIcon icon={bkmrked ? bookmarksSharp : bookmarkOutline} className="bkm-icon" />
                    </div>
                    {
                        !isPlatform('hybrid') && (
                            <div className='feed-footer-pdf-btn' onClick={async () => {
                                const { post_id, images, post_title, post_markdown, post_created, author } = feed;
                                setDownloading(true);

                                const logo = document.createElement('img');
                                logo.src = "https://uploads-ssl.webflow.com/627a29506a2dede14b076baa/627a2aa85a5be502794e2476_NI_Logo-p-500.png";
                                logo.className = "logo"
                                logo.style = "display:flex; width:150px; height:50px; padding-left:20px; object-fit:cover;"

                                const post = document.createElement('div');
                                post.style = "display:flex; flex-direction:column; width:100%; color:black";
                                post.append(logo);

                                const header = document.createElement('h4');
                                header.innerText = post_title;
                                header.style = "padding-left:20px;color:black";
                                post.append(header);

                                const postMarkDown = document.createElement('div');
                                postMarkDown.style = "display:flex; flex-direction:column; max-width:90%; height:auto;padding-left:20px;";
                                postMarkDown.innerHTML = marked.parse(post_markdown);
                                post.append(postMarkDown);

                                const postedDate = document.createElement('p');
                                postedDate.style = "padding-left:20px; color:black";
                                postedDate.innerText = moment(post_created).format('LL');
                                post.append(postedDate);

                                const authorName = document.createElement('p');
                                authorName.style = "padding-left:20px";
                                authorName.innerText = author.author_name;
                                post.append(authorName);

                                // const pageBreak = document.createElement('div');
                                // pageBreak.className = 'html2pdf__page-break';
                                // post.append(pageBreak);

                                for (const [index, image] of images.entries()) {
                                    const imageContainer = document.createElement('div');
                                    imageContainer.setAttribute('id', "svgToPrint");

                                    // if(index === 0){
                                    //     console.log('adding page break');
                                    //     const pageBreak = document.createElement('div');
                                    //     pageBreak.className = 'html2pdf__page-break';
                                    //     post.append(pageBreak);
                                    // }

                                    imageContainer.style = "display:flex; flex-direction:column;width:100%;height:800px; padding: 20px";
                                    const response = await fetch(image.img_url);
                                    if(response.headers.get('Content-Type') !== 'image/svg+xml'){
                                        imageContainer.innerHTML = `<img src="${image.img_url}" />`;
                                         post.append(imageContainer);
                                         continue;
                                    }
                                    const svgText = await response.text();
                                    const svgDoc = new DOMParser().parseFromString(svgText, 'image/svg+xml');
                                    console.log('hasAspectRatio', svgDoc.querySelector('svg').getAttribute('preserveAspectRatio') !== null);
                                    const hasAspectRatio = svgDoc.querySelector('svg').getAttribute('preserveAspectRatio') !== null;
                                    if(hasAspectRatio){
                                         imageContainer.innerHTML = `<img src="${image.img_url}" />`;
                                         post.append(imageContainer);
                                         continue;
                                    }

                                    imageContainer.innerHTML = svgText;
                                    post.append(imageContainer);
                                }
                                handleDownloadPDF(new XMLSerializer().serializeToString(post), post_title);
                            }} style={{


                            }} >
                                {
                                    downloading ? <Loader spin={true} size={'30px'} /> : <PictureAsPdfRounded />
                                }
                            </div>
                        )
                    }

                </div>
                 {
//                     !isPlatform('hybrid')&&(
//                         <div className='feed-footer-pdf-btn' onClick={()=>{
//                             const { post_id, images, post_title, post_markdown, post_created, author } = feed;
//                             setDownloading(true);
//                             var htmlContent = `
//                             <html>
//                                 <head>
//                                     <style>
//                                         .markdown_div:{
//                                             background-color:'red'
//                                         }
                                        
//                                         .image{
//                                             display:flex;
//                                             width:100px !important;
//                                             margin:0 auto !important;
//                                             height:auto !important;
//                                             object-fit:contain !important;
//                                         }
//                                     </style>
//                                 </head>
//                                 <body>
//                                     <div style="display:flex; flex-direction:column; width:100%; color:black" >
//                                         <img src="https://uploads-ssl.webflow.com/627a29506a2dede14b076baa/627a2aa85a5be502794e2476_NI_Logo-p-500.png" class="logo" style="display:flex; width:150px; height:50px; padding-left:20px; object-fit:cover;" />
//                                         <h4 style="padding-left:20px;color:black" >${post_title}</h4>
//                                         <div style="display:flex; flex-direction:column; max-width:90%; height:auto;padding-left:20px;" >
//                                             ${marked.parse(post_markdown)}
//                                         </div>
//                                         <p style="padding-left:20px; color:black">${moment(post_created).format('LL')}</p>
//                                         <p style="padding-left:20px;"> ${author.author_name} </p>
//                                         ${images.map(img => `<div style="display:flex; flex-direction:column; position:relative; width:100%; align-items:center; margin:0 auto;" ><img style="display:flex;width:100%;height:100%;object-fit:contain;" src="${img.img_url}" /><p>${img.img_caption}</p></div>`).join('')}
                                        
//                                     </div>
//                                 </body>
//                             </html>
//                             `
                            
//                             handleDownloadPDF(htmlContent, post_title);
//                         }} style={{
                            
        
//                         }} >
//                             {
//                                 downloading?<Loader spin={true} size={'30px'} />:<PictureAsPdfRounded />
//                             }
//                         </div>
//                     )
                }
            

            </div>
            <IonModal catch isOpen={open.open} onDidDismiss={() => {
                setOpen({ imgs: [], open: false })
            }} className="fullscreen-modal"  >
                <AppBar position="static" color="inherit">
                    <Tabs

                        variant="scrollable"
                        textColor='inherit'
                        allowScrollButtonsMobile
                        scrollButtons="on"
                        value={valueModal}
                        TabIndicatorProps={{
                            color: 'primary'
                        }}
                        onChange={handleChangeModal}
                        classes={classes}
                        aria-label="simple tabs example"
                        style={{ display: filtered_imgs.length > 1 ? "flex" : "none", color: 'grey' }}
                    >
                        {feed.images.map((image, index) => {
                            return (
                                <Tab key={index} label={image.img_caption ? image.img_caption : "IMG 1"} />
                            )
                        })}
                    </Tabs>
                </AppBar>
                {[...filtered_imgs].map((url, index) => {
                    return (
                        <TabPanel key={index} value={valueModal} index={index}>
                            <div style={{ display: 'flex', width: '100%', height: '100%', justifyContent: 'center' }} >
                                {
                                    feed.video_url ? (
                                        <MuxPlayer streamType="on-demand" playbackId={feed.video_url}></MuxPlayer>
                                    ) : (
                                        <QuickPinchZoom onUpdate={onUpdate}>
                                            <animated.img onClick={() => setOpen({ imgs: feed.images, open: true })} ref={imgRef} src={/.svg|.SVG/.test(url) ? url : url.split("?")[0]} className="feed-body-image" alt={feed.post_caption} />
                                        </QuickPinchZoom>


                                    )
                                }
                            </div>
                        </TabPanel>
                    )
                })}
            </IonModal>
        </div>
    )
}

export default HomeList;