import { useAuth0 } from '@auth0/auth0-react';
import { IonContent, IonIcon, IonInput} from '@ionic/react'
import { closeOutline, search } from 'ionicons/icons';
import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
// import Header from '../components/Header';
import HomeList from '../components/HomeList';
import Loader from '../utils/Loader';
import { getSearchContent, handleBookmark, updateImpression } from '../utils/requests';
import './Search.css';

function Search() {
    const { user } = useAuth0();
    const [loading, setLoading] = useState(false);
    const [ , setLoaded] = useState(false);
    const [results, setResults] = useState({ list: [], status: null });
    const [page, setPage] = useState(1);
    const scrollerRef = useRef(null);
    const ref = useRef(null);
    const [query, setQuery] = useState('');
    const [re_searches, setRSearches] = useState([]);
    const [ , setBKUpdate] = useState(true);
    const dispatch = useDispatch();
    // const bookmarkState = useSelector(state => state.bookmark);
    const impressionContext = useSelector(state => state.impressionState);
    const [impressions, setImpressions] = useState([]);
    const appState = useSelector(state => state.appState);

    useEffect(() => {
        if (query.length > 2) {
            getSearchContent(user.email, query, page)
                .then(response => {
                    var { list, status } = results;

                    setResults({ list: [...list, ...response.data], status: status });
                    setLoading(false);
                })
        }
    }, [page])




    useEffect(() => {
        const container = scrollerRef.current;
        const elements = [];
        let ts = [];

        const handleScroll = () => {
            var children = container.children;
            var el_check = [...elements];

            for (let i = 0; i < children.length; i++) {
                var child = children[i];
                if (child.getBoundingClientRect().y <= 300) {
                    let curChild_id = child.getAttribute('data-post-id')
                    if (!elements.includes(curChild_id) && curChild_id !== undefined) {
                        elements.unshift(curChild_id);
                        const date = new Date(Date.now()).toISOString().replace('T', ' ').replace('Z', ' ')
                        ts.unshift(date);
                    }
                }
            }
            if (el_check.length !== elements.length) {
                var final_array = [];
                for (let j = 0; j < elements.length; j++) {
                    final_array.unshift([elements[j], ts[j]])
                }
                setImpressions([...final_array])
            }

            if (
                container.scrollTop + container.clientHeight + 5 >= container.scrollHeight &&
                !loading
            ) {

                setPage((prevPage) => prevPage + 1);
            }
        };
        if (container) {
            container.addEventListener("scroll", handleScroll);
        }
    }, [loading]);

    const handle_fetch_data = (value) => {
        console.log(value, "valll")
        getSearchContent(user.email, value, page)
            .then(response => {
                if (response.data.length > 0) {
                    console.log(response.data, "ppp")
                    setPage(1);
                    setResults({ status: null, list: response.data });

                }
                else {
                    setResults({ status: 404, list: [] })
                }
                var recent_search = JSON.parse(localStorage.getItem('recent_search')) || [];
                if (!re_searches.includes(value)) recent_search.unshift(value);
                if (recent_search.length > 3) {
                    recent_search.pop()
                }
                localStorage.setItem('recent_search', JSON.stringify(recent_search))

                setLoading(false);
                setLoaded(true)
            }).catch(error => {
                setLoading(false)
                console.log(error)
            })
    }

    const handleSubmit = (e) => {
        const value = document.getElementById('search-input').value;
        if (value.length - 1 === 0) {
            setResults({ status: null, list: [] });
            setQuery('');
            setPage(1);

        }
        if (e.key === "Enter" && value.length > 1) {
            const value = document.getElementById('search-input').value;
            console.log(value, "kkkcdscnsdcjhdks")
            setLoading(true);
            handle_fetch_data(value);
            setQuery(value);
        }

    }
    useEffect(() => {
        const recent_search = JSON.parse(localStorage.getItem('recent_search')) || [];
        setRSearches(recent_search);
    }, [])

    const handleBookmarkBtn = async (item) => {
        return new Promise((resolve, reject) => {
            handleBookmark(user.email, item.post_id)

                .then(response => {
                    var temp = [...results];
                    if (response.status === 204) {
                        setBKUpdate(true);
                        temp.forEach(function (post) {
                            if (post.post_id === item.post_id) {
                                post.bookmarks = []

                            }
                        })

                        dispatch({ type: "REMOVE_BOOKMARK", payload: item });
                    }
                    else if (response.status === 201) {
                        temp.forEach(post => {
                            if (post.post_id === item.post_id) {
                                post.bookmarks.unshift({ ...response.data })
                            }

                        })
                        dispatch({ type: "ADD_BOOKMARK", payload: item });
                    }
                    setBKUpdate(true)
                    setResults(temp)
                    resolve(response.status)
                })
                .catch(error => {
                    console.log(error, "erro")
                    reject(error.status)
                })
        })
    }

    // useEffect(()=>{

    //     const {post_removed_bk} = bookmarkState;
    //     if(post_removed_bk !== null && bkmrkUpdate ){
    //         setLoading(true);
    //         var updated = [...results];
    //         updated.forEach(item=>{
    //             if(item.post_id === post_removed_bk.post_id){
    //                 item.bookmarks = [];
    //             }
    //             return
    //         })
    //         setResults(updated);
    //         dispatch({type:"RESET_BOOKMARK", payload:null});
    //         setLoading(false);

    //     }
    // }, [bookmarkState])
    useEffect(() => {
        const handleBlur = (e) => {
            const { impressions } = impressionContext;
            if (impressions.length > 0) {
                updateImpression(user.email, impressions)
                    .then(response => {
                        console.log(response.data)
                        setImpressions([]);
                    })
                    .catch(error => {
                        console.log(error.message)
                    })
            }
        }
        const handleVisibility = () => {
            const { impressions } = impressionContext;
            if (impressions.length > 0) {
                updateImpression(user.email, impressions)
                    .then(response => {

                        setImpressions([]);
                    })
                    .catch(error => {
                        console.log(error.message)
                    })
            }
        }

        // const handleAppStateChange = (e) => {
        //     console.log(JSON.stringify(e));
        // }

        window.addEventListener('blur', handleBlur)
        window.addEventListener('close', handleVisibility);


        return () => {
            window.removeEventListener('blur', handleBlur)
            window.removeEventListener('close', handleVisibility)
        }
    }, [impressionContext]);


    useEffect(() => {
        const { tab, prevTab } = appState;
        if (tab !== prevTab) {
            if (impressions.length > 0) {
                updateImpression(user.email, impressions)
                    .then(response => {
                        setImpressions([]);
                    })
                    .catch(error => {
                        console.log(error.message)
                    })
            }
        }
    }, [appState])

    return (
        <div style={{ display: 'flex', width: '100%', height: '100vh', }} >
            <IonContent>
                <div className="search-container">
                    <div className="search-header">
                        <IonIcon icon={search} style={{ paddingLeft: '1rem', }} />
                        <IonInput onKeyDown={e => handleSubmit(e)} id='search-input' placeholder='Search' />
                    </div>
                    <div className="search-recent">
                        {
                            re_searches.map((re, index) => (
                                <div key={index} className="search-recent-container">
                                    <div className='search-recent-left' onClick={() => {
                                        var re_filtered = [...re_searches].filter(s => s !== re);
                                        setRSearches(re_filtered);
                                        localStorage.setItem('recent_search', JSON.stringify(re_filtered))

                                    }}>
                                        <IonIcon icon={closeOutline} size={30} />
                                    </div>
                                    <div className='search-recent-right' onClick={() => {
                                        handle_fetch_data(re);
                                        document.getElementById('search-input').value = re;
                                    }} >{re}</div>
                                </div>
                            ))
                        }
                    </div>
                    <div className='search-body'>
                        {
                            loading ? (
                                <Loader size={40} />
                            ) : (
                                <>
                                    {
                                        results.status === 404 ? (
                                            <div style={{
                                                display: 'flex',
                                                width: '100%',
                                                height: '100%',
                                                justifyContent: 'center',
                                                alignItems: 'center'
                                            }} >
                                                No results found
                                            </div>
                                        ) : (
                                            <div style={{
                                                width: "100%",
                                                height: '100%',
                                                overflowY: 'scroll'

                                            }} ref={scrollerRef}  >
                                                {
                                                    results.list.map(item => (

                                                        <HomeList key={item.post_id} item={item} handleBookmarkBtn={handleBookmarkBtn} isbkmrked={item.bookmarks.length > 0} />
                                                    ))
                                                }

                                            </div>
                                        )
                                    }
                                </>
                            )
                        }
                        <div ref={ref}></div>
                    </div>
                </div>
            </IonContent>
        </div>
    )
}

export default Search