import axios from "axios";
import { api_endpoint } from "../api.config";




// Get feeds 
export function getfeedPosts(email, page){
    return new Promise((resolve, reject)=>{
        axios.post(`${api_endpoint}/posts`, {page, email:email})
            .then(response=>{
                resolve(response.data);
            })
            .catch(error=>{
                reject(error)
            })
    })
}



// Get bookmarks

export function getbookmarks(email){
    return new Promise((resolve, reject)=>{
        axios.post(`${api_endpoint}/bookmarks/get`, {email:email})
            .then(response=>{
                resolve(response.data);
            })
            .catch(error=>{
                reject(error)
            })
    })
}


// Add or remove bookmark
export function handleBookmark(email, post_id){
    return new Promise((resolve, reject)=>{
        axios.post(`${api_endpoint}/bookmarks/add`, {
            email:email,
            post_id:post_id
        }, {headers:{
            'Content-Type':'application/json'
        }})
            .then(response=>{
                resolve(response);
            })
            .catch(error=>{
                reject(error.message);
            })
    })
}


// Store firebase registration token

export function sendToken(token){
    return new Promise((resolve, reject)=>{
        axios.post(`${api_endpoint}/fcm/token`, {
            token:token
        }, {headers:{
            'Content-Type':'application/json'
        }})
            .then(response=>{
                resolve(response);
            })
            .catch(error=>{
                reject(error.message);
            })
    })
}


export function getCategories(email){
    return new Promise((resolve, reject)=>{
        axios.post(`${api_endpoint}/categories/get`, {
            email: email
        }, {headers:{
            'Content-Type':'application/json'
        }})
            .then(response=>{
                resolve(response);
            })
            .catch(error=>{
                reject(error.message);
            })
    })
}


// get category posts

export function getCategoryFeed(email, tag, page){
    return new Promise((resolve, reject)=>{
        axios.post(`${api_endpoint}/categories/feed`, {
            email: email,
            post_tag: tag,
            page:page
        }, {headers:{
            'Content-Type':'application/json'
        }})
            .then(response=>{
                resolve(response);
            })
            .catch(error=>{
                reject(error.message);
            })
    })
}


// search 

export function getSearchContent(email, query, page){
    return new Promise((resolve, reject)=>{
        axios.post(`${api_endpoint}/search/posts`, {
            email: email,
            query: query,
            page
        }, {headers:{
            'Content-Type':'application/json'
        }})
            .then(response=>{
                resolve(response);
            })
            .catch(error=>{
                reject(error.message);
            })
    })
}


// Update or create fcm token table 


export function fcmToken(email, token){
    return new Promise((resolve, reject)=>{
        axios.post(`${api_endpoint}/fcm/token/update`, {
            email: email,
            token:token
        }, {headers:{
            'Content-Type':'application/json'
        }})
            .then(response=>{
                resolve(response);
            })
            .catch(error=>{
                reject(error.message);
            })
    })
}


// Update impressions
export function updateImpression(email, impressions){
    return new Promise((resolve, reject)=>{
        axios.post(`${api_endpoint}/impressions/add`, {
            email: email,
            impressions: impressions
        }, {headers:{
            'Content-Type':'application/json'
        }})
            .then(response=>{
                resolve(response);
            })
            .catch(error=>{
                reject(error.message);
            })
    })
}



// Get single post

export function getSinglePost(email, post_id){
    return new Promise((resolve, reject)=>{
        axios.post(`${api_endpoint}/posts/post`, {post_id:post_id, email:email},{headers:{
            'Content-Type':'application/json'
        }})
            .then(response=>{
                resolve(response.data);
            })
            .catch(error=>{
                reject(error)
            })
    })
}

export function Test(email, post_id){
    return new Promise((resolve, reject)=>{
        axios.post(`${api_endpoint}/test`)

        .then(response=>{
            resolve(response.data);
        })
        .catch(error=>{
            reject(error)
        })
    })
}
export function downloadPdf(data){
    console.log(data, "pppp")
    return new Promise((resolve, reject)=>{
        axios.post(`${api_endpoint}/post/pdf`, data, {
            responseType:'blob',
            headers:{
                'Content-Type':'application/json'
            }
        })

        .then(response=>{
            resolve(response.data);
        })
        .catch(error=>{
            reject(error)
        })
    })
}


export function signup(row){
    return new Promise((resolve, reject)=>{
        axios.post(`${api_endpoint}/user/create`, {...row}, {
            headers:{
                "Content-Type":'application/json'
            }
        })

        .then(response=>{
            resolve(response);
        })
        .catch(error=>{
            reject(error)
        })
    })
}



