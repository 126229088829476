import {  useEffect, useRef, useState } from 'react'
// import { IonContent,IonPage } from '@ionic/react';
import { IonContent } from '@ionic/react';

import { useDispatch, useSelector } from 'react-redux';

import { getfeedPosts, updateImpression } from '../utils/requests';
import Loader from '../utils/Loader';
import { useAuth0 } from '@auth0/auth0-react';


import './home.css'
import HomeList from '../components/HomeList';
import { useHistory } from 'react-router';
// import SwipeRefreshView from 'react-swipe-refresh';
import PullToRefresh from 'react-simple-pull-to-refresh';



function Home() {
    const scrollerRef = useRef(null);
    const postRef = useRef([]);
    const [ data, setData ] = useState([]);
    const [ page, setPage ] = useState(1);
    // const [ prevpage, setPrevPage ] = useState(1);
    const [ loading, setLoading ] = useState(true);
    const [ feedLoading, setFLoading ] = useState(false);
    // const [ open, setOpen ] = useState({state:false, post:[], loading:true, error:null});
    const dispatch = useDispatch();
    
    const appState = useSelector(state=>state.appState);
    const appThemeContext = useSelector(state=>state.themeState);
    const singlePostState = useSelector(state=>state.singlePostState);
    const tabState = useSelector(state=>state.tabState);
    const [ curTheme, setCurTheme ] = useState(null);
    // const [ darkMode, setDM ] = useState(false);
    const [ darkMode, ] = useState(false);
    // const impressionContext = useSelector(state=>state.impressionState);
    const [ impressions, setImpressions ] = useState([]);
    const [isRefreshing, setIsRefreshing] = useState(false);
    // const [refreshOffset, setRefreshOffset] = useState(0);
    const [ , setRefreshOffset] = useState(0);
    const [ shouldFetch, setShouldFetch ] = useState(true);
    const {user} = useAuth0();
    const history  = useHistory();
    // const handleClose = () =>{
    //     localStorage.removeItem('persist_page');
    //     dispatch({type:"SINGLE_POST_TOGGLE", payload:null})
    //     setOpen({...open,loading:false, post:[], state:false})
    // }

    
    
    
    

    useEffect(()=>{
        if(shouldFetch){
            getfeedPosts(user.email, page)
            .then(newposts=>{
                setData(prev=>[...prev, ...newposts]);
                setLoading(false);
                setFLoading(false);
            })
            .catch(e=>{
                if(!e.status){
                    dispatch({type:0, payload:null})
                }
            })
        }
    }, [page])
    useEffect(() => {
        function handleScroll() {
            console.log("jiiiiiiiii")
          const offset = window.pageYOffset;
      
          if (offset < -50 && !isRefreshing) {
            setIsRefreshing(true);
            setIsRefreshing(false);
          }
      
          setRefreshOffset(offset);
        }
      
        window.addEventListener('scroll', handleScroll);
      
        return () => window.removeEventListener('scroll', handleScroll);
      }, [isRefreshing]);



    useEffect(() => {
        const container = scrollerRef.current;
        const elements = [];
        let ts = [];

        
        
        const handleScroll = () => {
            var children = container.children;
            let el_check = [...elements];
            for(var i=0; i<children.length; i++){
                var child = children[i];
                if(child.getBoundingClientRect().y <= 300){
                    let curChild_id = child.getAttribute('data-post-id')
                    if(!elements.includes(curChild_id) && curChild_id !== undefined){
                        elements.unshift(curChild_id);
                        const date = new Date(Date.now()).toISOString().replace('T', ' ').replace('Z', ' ')
                        ts.unshift(date);
                    }
                }
            }
            if(el_check.length !== elements.length){
                var final_array = [];
                for(let i=0; i<elements.length; i++){
                    final_array.unshift([elements[i], ts[i]])
                }
                setImpressions([...final_array])
            }

          if (
            container.scrollTop + container.clientHeight + 5 >= container.scrollHeight &&
            !feedLoading
          ) {
            setPage((prevPage) => prevPage + 1);
            setFLoading(true);
          }
          
        };
        
        console.log(container, "container")
        if(container){
            container.addEventListener("scroll", handleScroll);
        }
      }, [loading]);

      // un-used variables
    // var elements = [];
    // var ts = []



    // const handleScroll = (e) => {
    //     var container = e.currentTarget;
    //     var children = container.children;
    //     for(var i=0; i<children[1].length; i++){
    //         var child = children[i];
    //         if(child.getBoundingClientRect().y <= 300){
    //             let curChild_id = child.getAttribute('data-post-id')
    //             if(!elements.includes(curChild_id) && curChild_id !== undefined){
    //                 elements.unshift(curChild_id);
    //                 const date = new Date(Date.now()).toISOString().replace('T', ' ').replace('Z', ' ')
    //                 ts.unshift(date);
    //             }
    //         }
    //     }
    //     if(el_check.length !== elements.length){
    //         var final_array = [];
    //         for(var i=0; i<elements.length; i++){
    //             final_array.unshift([elements[i], ts[i]])
    //         }

    //         console.log(final_array, "ffinnn")
    //         setImpressions([...final_array])
    //     }

    //     if (
    //     container.scrollTop + container.clientHeight + 5 >= container.scrollHeight &&
    //     !feedLoading
    //     ) {
    //     setPage((prevPage) => prevPage + 1);
    //     setFLoading(true);
    //     }
    
    // };


    // Feed impressions
    useEffect(()=>{
        const {single_post_open} = appState
        if(single_post_open!==null){
            history.push(`/post/${single_post_open}`);
        }
        
    }, [singlePostState])



    


    useEffect(()=>{
        const {tab, prevTab} = tabState;
        if(tab !== prevTab || tab === null){
            if(impressions.length > 0){
                updateImpression(user.email, impressions)
                    .then(response=>{
                        setImpressions([]);
                    })
                    .catch(error=>{
                        console.log(error.message)
                    })
            }
        }
    }, [tabState])

    useState(()=>{
        const { currentTheme } = appThemeContext;
        if(currentTheme){
            setCurTheme(currentTheme)
        }
    }, [appThemeContext])
    
    
    const handleRefresh = async() =>{
        try {
            setShouldFetch(false);
            const newposts = await getfeedPosts(user.email, 1);
            setPage(1);
            setData([...newposts]);
            setLoading(false);
            setFLoading(false);
            setShouldFetch(true);
        } catch (error) {
            if(!error.status){
                dispatch({type:0, payload:null})
            }
        }
    }
  return (
    <div style={{display:'flex', width:'100%', height:'100vh', }} >
      <IonContent fullscreen={false} style={{}} >
        
        {
            loading?(
                <div style={{display:'flex', width:'100%',  height:'100vh', justifyContent:'center', alignItems:'center'}} >
                    <Loader size={40} />
                </div>
            ):(
                

                    <PullToRefresh
                        onRefresh={handleRefresh}
                        isPullable={true}
                        pullingContent={""}

                    >
                        <div  style={{
                            position:'relative',
                            width:'100%',
                            height:'100%',
                            // height:'100%',
                            overflowY:'scroll',
                            backgroundColor:'blur'
                            }} ref={scrollerRef} >
                                {   
                                data.map(item=>{
                                        return(
                                            <HomeList key={item.post_id} dm={darkMode} refs={postRef} item={item} curTheme={curTheme} from="home"/>
                                        )
                                    })
                                }
                            </div>
                    </PullToRefresh>
                        
            )
        }
        
      </IonContent>
      </div>
  )
}

export default Home