import { useEffect, useState } from 'react';
import { IonContent, IonModal } from '@ionic/react';

import './Categories.css';
import { getCategories, getCategoryFeed, handleBookmark, updateImpression } from '../utils/requests';
import { useAuth0 } from '@auth0/auth0-react';
import Loader from '../utils/Loader';
import { useDispatch, useSelector} from 'react-redux';
import CatModel from '../components/CatModel';
// import Header from '../components/Header';

function Categories() {
  const [ cats, setCats ] = useState([]);
  const [ open, setOpen ] = useState({open:false, state:{}});
  const [ catFeed, setCatFeed ] = useState([])
  const [ loading, setLoading ] = useState(false);
  const [ tag, setTag ] = useState(null);
  const dispatch = useDispatch();
  const impressionContext = useSelector(state=>state.impressionState);
  const appThemeContext = useSelector(state=>state.themeState);


  const {user} = useAuth0();
  useEffect(()=>{


    getCategories(user.email).then(response=>{
      setCats(response.data);
      setLoading(false)
    }).catch(error=>{
      setLoading(false)
    })
  }, []);

  const get_cat_posts = (tag) =>{
    setTag(tag);
    getCategoryFeed(user.email, tag, 1)
    .then(response=>{
      setCatFeed(response.data);
      setOpen({open:true, state:{title:tag}});
      setLoading(false);

    })
    .catch(error=>{
      console.log(error)
    })
  }


  const handleBookmarkBtn = ( item) =>{
    return new Promise((resolve, reject)=>{
        handleBookmark(user.email, item.post_id)
        
        .then(response=>{
            var temp = [...catFeed];
            if(response.status === 204){
                temp.map(post=>{
                    if(post.post_id === item.post_id){
                        post.bookmarks = []
                    }
                    
                })
                
                dispatch({type:"REMOVE_BOOKMARK", payload:item});
            }
            else if(response.status === 201){
                temp.map(post=>{
                    if(post.post_id === item.post_id){
                        post.bookmarks.unshift({...response.data})
                    }
                    
                })
                dispatch({type:"ADD_BOOKMARK", payload:item});
            }
            setCatFeed(temp)
            resolve(response.status)
        })
        .catch(error=>{
            reject(error.status)
        })
    })
  }




  // useEffect(()=>{
  //   const {device_theme} = darkModeState;
  //   if(device_theme){
  //     if(device_theme === "DARK"){
  //       setDM(true)
  //     }
  //     else{
  //       console.log("lighhttt")
  //       setDM(false)
  //     }
  //   }
  // }, [darkModeState])
  return (
    <div style={{display:'flex', width:'100%', height:'100vh', }} >
      {/* <div className="header-cont">
        <Header />
      </div> */}
      <IonContent className='cat-content'>
      {
        loading?(
          <Loader size={50} />
        ):(
          <div className='cat-container' >
            <div className="cat-header">
              <div className="cat-header-title">
                Categories
              </div>
              <div className="cat-avatar">

              </div>
            </div>
            <div className="cat-body">
            {
              cats.map((cat, index)=>(
                <div key={index} className="cat-item" onClick={()=>{
                  get_cat_posts(cat.post_tag)
                  
                }} >
                  <div className="cat-item-img">
                    {cat.post_tag_img&&<img src={`${cat.post_tag_img}`} alt="img" />}
                  </div>
                  <div className="cat-item-footer">
                    <div id='open-modal' className="cat-item-title">
                      {cat.post_tag}
                    </div>
                    <div className="cat-item-right">
                      {cat.post_tag_icon&&<img src={appThemeContext.currentTheme === "DARK"?cat.post_tag_icon_dark_mode:cat.post_tag_icon} alt="img" />}
                    </div>
                  </div>
                </div>
              ))
            }
            </div>
            
          </div>  
        )
      }
      <IonModal className='cat-modal-container' isOpen ={open.open} onDidDismiss={()=>{
        const { impressions } = impressionContext;
        if(impressions.length > 0){
          updateImpression(user.email, impressions)
          .then(response=>{
            console.log(response.data)
            dispatch({type:"REMOVE_IMPRESSIONS", payload:null})
          })
          .catch(error=>{
            console.log(error.message);
          })
        }
        setOpen({state:{}, open:false });
        
      }}>
        
        <CatModel open={open} catFeed={catFeed} handleBookmarkBtn={handleBookmarkBtn} setOpen={setOpen} tag={tag} />
      </IonModal>
      </IonContent>
    </div>
  )
}

export default Categories