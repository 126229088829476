import { isPlatform } from "@ionic/react";
import { app_env } from './api.config';


//dev-client-config
// export const domain = "dev--qig3re2.us.auth0.com";
// export const clientId = "bKi1T1edo9PHSvdYnMNpFyoJprUccLVp"


//prod-clientId
export const domain = "login.nutritionintegrated.com";
export const clientId = "upBJH42TIMT3UHfEF66wy1qBsBaCK5jM";

const appId = "com.nutritionintegrated.anie";

// Use `auth0Domain` in string interpolation below so that it doesn't
// get replaced by the quickstart auto-packager
const auth0Domain = domain;

export const iosOrAndroid = isPlatform('hybrid');
let app_url = "https://anie.nutritionintegrated.com";
// let app_url = "https://gray-water-06df31e10.3.azurestaticapps.net"
if(app_env === "development"){
  app_url = "http://localhost:3000"
}


export const callbackUri = iosOrAndroid
  ? `${appId}://${auth0Domain}/capacitor/${appId}/callback`
  : app_url;