
import { applyMiddleware, createStore } from 'redux';
import { combineReducers } from 'redux';
import { user } from './reducers/user';
import thunk from 'redux-thunk';
import { app } from './reducers/app';
import { bookmarkReducer } from './reducers/bookmarks';
import { error } from './reducers/error';
import { impressionReducer } from './reducers/impressions';
import { composeWithDevTools } from 'redux-devtools-extension';
import { tabEvent } from './reducers/tabevent';
import { singlePost } from './reducers/singlepost';
import { darkMode } from './reducers/darkMode';
import { appTheme } from './reducers/appTheme';


const initialState = {};

const rootReducer = combineReducers({
    userState: user,
    appState: app,
    bookmark: bookmarkReducer,
    error: error,
    impressionState: impressionReducer,
    tabState: tabEvent,
    singlePostState: singlePost,
    darkModeState: darkMode,
    themeState: appTheme
})



const middleware = [thunk]
export const store = createStore(rootReducer, initialState, composeWithDevTools(applyMiddleware(...middleware)))