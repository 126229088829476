import { LOGIN_OK } from "../types";
const initialState = {
    userInfo:null
}
export const user = (state = initialState, action) =>{
    switch (action.type) {
        case LOGIN_OK:
            return{
                ...state,
                userInfo:action.payload
            }
    
        default:
            return{
                ...state
            }
    }
}