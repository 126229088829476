import { useFormInput } from "./util";

export const useSignupFields = () => {

    return [
        {
            id: "name",
            label: "Name",
            required: true,
            input: {

                props: {

                    type: "text",
                    placeholder: "Joe Bloggs"
                },
                state: useFormInput("")
            }
        },
        {
            id: "company",
            label: "Company",
            required: true,
            input: {

                props: {

                    type: "text",
                    placeholder: "company name"
                },
                state: useFormInput("")
            }
        },
        {
            id: "email",
            label: "Company email",
            required: true,
            input: {

                props: {

                    type: "email",
                    placeholder: "joe@bloggs.com",
                    autocomplete: "email"
                },
                state: useFormInput("")
            }
        },
        {
            id: "size",
            inputs: [
                { label: "1-9", value: 1 },
                { label: "10-49", value: 10 },
                { label: "50-99", value: 50 },
                { label: "100+", value: 100 },
            ]
        },
        {
            id: "location",
            label: "Location",
            required: false,
            input: {

                props: {

                    type: "text",
                    placeholder: "London"
                },
                state: useFormInput("")
            }
        },
        {
            id: "dataset",
            inputs: ["Bars", "Protein RTD", "Protein RTM", "Pre Workouts", "Gaming", "Energy Gels", "Protein Cereal", "Electrolytes",
                "Gummies",
                "Superfood Blends",
                "Collagen",
            ]
        },
        {
            id: "hear",
            label: "How did you hear about us?",
            required: false,
            input: {

                props: {

                    type: "text",
                    placeholder: "..."
                },
                state: useFormInput("")
            }
        },
        {
            id: "password",
            label: "Password",
            required: true,
            input: {

                props: {

                    type: "password",
                    placeholder: "*****"
                },
                state: useFormInput("")
            }
        },
    ];
}

export const useLoginFields = () => {

    return [

        {
            id: "email",
            label: "Email",
            required: true,
            input: {

                props: {
                    type: "email",
                    placeholder: "joe@bloggs.com"
                },
                state: useFormInput("")
            }
        },
        {
            id: "password",
            label: "Password",
            required: true,
            input: {

                props: {
                    type: "password",
                    placeholder: "*******"
                },
                state: useFormInput("")
            }
        }
    ];
}